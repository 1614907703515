import { FC, useEffect, useState } from "react";
import {
    AutoComplete,
    AutoCompleteInput,
    AutoCompleteItem,
    AutoCompleteList,
} from "@choc-ui/chakra-autocomplete";
import { StaffDto, StaffAuthLevel } from "../../types/staff";
import { Spinner, useToast } from "@chakra-ui/react";
import { getAllStaffList } from "../../api/staff";
import { useStaffStore } from "../../store/staffStore";

export type StaffListProps = {
    prefetchedStaffList?: StaffDto[];
    onSelectOption: (staffId: string) => void;
    includeAllOption?: boolean;
    defaultSelectedId?: string;
    inputWidth?: string;
    inputVariant?: string;
}

export const StaffSelect: FC<StaffListProps> = (props: StaffListProps) => {

    const toast = useToast();
    const [staffList, setStaffList] = useState<StaffDto[]>(props.prefetchedStaffList ?? []);
    const [loading, setLoading] = useState(props.prefetchedStaffList ? false : true);
    const currentStaff = useStaffStore((state) => state.currentStaff);

    const getStaffDetails = async () => {
        setLoading(true);
        const resp = await getAllStaffList(currentStaff?.organizationId || "");
        if (resp.message) {
            setLoading(false);
            toast({
                title: "Failed to fetch staff info",
                description: resp.message,
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
        const filteredStaff = (resp.data || []).filter(staff => staff.authLevel !== StaffAuthLevel.ADMIN);
        setStaffList(filteredStaff);
        setLoading(false);
    }

    useEffect(() => {
        if (props.prefetchedStaffList && props.prefetchedStaffList.length > 0) {
            const filteredStaff = props.prefetchedStaffList.filter(staff => staff.authLevel !== StaffAuthLevel.ADMIN);
            setStaffList(filteredStaff);
            return;
        }
        getStaffDetails();
    }, [props.prefetchedStaffList]);

    if (loading) {
        return <Spinner />;
    }

    return (
        <AutoComplete
            defaultValue={getSeletecStaffName(staffList, props.defaultSelectedId)}
            openOnFocus
            listAllValuesOnFocus
            onSelectOption={(params) => {
                if (params.item.value === "") { // all option
                    props.onSelectOption("");
                    return;
                }
                // ideal solution here is to get the key of the selected option but the
                // library does not provide that. So we have to filter the staffList
                // this code will break if staff names are not unique, but considering the dropdown only
                // displays names, the assumption is staff names should be unique
                const filtered = staffList.filter((staff) => staff.name === params.item.value);
                if (filtered.length === 0) return; // should not happen
                props.onSelectOption(filtered[0].id);
            }}
        >
            <AutoCompleteInput
                placeholder="Staff"
                width={props.inputWidth}
                variant={props.inputVariant}
            />
            <AutoCompleteList>
                {props.includeAllOption && <AutoCompleteItem
                    key={"all"}
                    value={""}
                >
                    {"All"}
                </AutoCompleteItem>}
                {staffList.map((staff) =>
                (
                    <AutoCompleteItem
                        key={staff.id}
                        value={staff.name}
                    >
                        {staff.name}
                    </AutoCompleteItem>
                )
                )}
            </AutoCompleteList>
        </AutoComplete>
    )
}

function getSeletecStaffName(staffList: StaffDto[], selectedId: string | undefined): string {
    if (!selectedId) return "";
    const selectedStaff = staffList.find((staff) => staff.id === selectedId);
    return selectedStaff?.name ?? "";
}
