import { PatientActivity } from "../../types/patient";
import { getMonthStr } from "../../utils/date";

export const groupActivitiesByMonth = (
  activities: PatientActivity[]
): PatientActivity[][] => {
  const groups: { [key: string]: PatientActivity[] } = {};

  // Group notes by month
  activities.forEach((activity) => {
    if (activity.date) {
      const date = new Date(activity.date);
      const monthYear = `${date.getMonth() + 1}-${date.getFullYear()}`;
      if (!groups[monthYear]) {
        groups[monthYear] = [];
      }
      groups[monthYear].push(activity);
    }
  });

  // Convert grouped notes object to array and sort by month-year in reverse chronological order
  const result: PatientActivity[][] = Object.entries(groups)
    .sort((a, b) => {
      const [monthA, yearA] = a[0].split('-').map(Number);
      const [monthB, yearB] = b[0].split('-').map(Number);
      // Compare years first
      if (yearA !== yearB) {
        return yearB - yearA;
      }
      // If years are equal, compare months
      return monthB - monthA;
    })
    .map(([, value]) => value);

  return result;
};

export const getGroupDateHeader = (group: PatientActivity[]) => {
  return group.length > 0 && group[0].date
    ? getMonthStr(group[0].date) + " " + new Date(group[0].date).getFullYear()
    : "";
};

export function getFirstName(fullName: string) {
  if (!fullName) {
    return '';
  }
  return fullName.split(' ')[0];
}
