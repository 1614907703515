import { FC, useEffect, useState } from 'react';
import { MetricDetail } from '../../types/metricDetail';
import { getMetricDetail } from '../../api/metricDetail';
import {
    useToast,
    Spinner,
    Center,
    VStack,
    Divider,
    Container,
    Box,
    Button,
    Text,
    Heading,
    Icon,
    HStack,
    FormLabel,
    FormControl,
    Input,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { Logo } from '../../Logo';
import FormField from "../../components/Form/FormField";
import { FormTypeField, ResponseType } from '../../types/form';
import { ArrowLeftMd } from 'react-coolicons';
import { useNavigate } from 'react-router-dom';
import { createAllPatientMetrics } from '../../api/patientMetric';
import { PatientMetricDto } from '../../types/form';
import { ROUTES } from '../../constants';
import { convDateTimeToISO } from '../../utils/date';

const MetricForm: FC = () => {
    const toast = useToast()
    const [metricDetail, setMetricDetail] = useState<MetricDetail>()
    const [loading, setLoading] = useState(true)
    const [propertyValues, setPropertyValues] = useState<Record<string, string>>({})
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [recordedDate, setRecordedDate] = useState(new Date())
    const { metricId, clientId } = useParams();
    const navigate = useNavigate();

    const onPropertyChange = (key: string, value: string) => {
        setPropertyValues({ ...propertyValues, [key]: value })
    }
    const fetchMetricDetail = async () => {
        setLoading(true)
        let resp = await getMetricDetail(metricId as string)
        if (resp.message || !resp.data) {
            toast({
                title: "Failed to fetch metric detail",
                description: resp.message,
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }
        setMetricDetail(resp.data)
        setLoading(false)
    }
    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let patientMetrics: PatientMetricDto[] = []
        for (let propertyId in propertyValues) {
            patientMetrics.push({
                id: "", // empty id for creating new patient metric
                patientId: clientId as string,
                propertyId: propertyId,
                value: parseFloat(propertyValues[propertyId]),
                timestamp: recordedDate.getTime().toString(),
            })
        }
        try {
            await createAllPatientMetrics(patientMetrics)
            toast({
                title: "Success",
                description: "Data saved successfully!",
                status: "success",
                duration: 9000,
                isClosable: true,
            });
            setIsSubmitted(true)
        } catch (error: any) {
            toast({
                title: "Failed to save data",
                description: error?.message || "An error occurred",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    }

    useEffect(() => {
        fetchMetricDetail()
    }, [metricId])

    if (loading) {
        return (
            <Center h={"100vh"}>
                <Spinner />
            </Center>
        )
    }
    if (isSubmitted) {
        return <ThankYouSection />
    }
    return (
        <>
            <Icon
                as={ArrowLeftMd}
                color={"blue.900"}
                fontSize={"4xl"}
                onClick={() => { navigate(-1) }}
                mt={5}
                ml={5}
            />
            <VStack ml={5} mr={5}>
                <Logo />
                <Heading as="h1" size="lg">{metricDetail?.title}</Heading>
                <Text>{`${metricDetail?.unitDescription}`}</Text>
                <Divider mb={4} />
                <Container maxW="full" textAlign={"left"}>
                    <Box as="form" onSubmit={onSubmit}>
                        {metricDetail?.properties.map((property, index) => {
                            let field: FormTypeField = {
                                key: property.propertyId,
                                question: property.question,
                                responseType: ResponseType.PROPERTY,
                                label: property.propertyId,
                                isMultiSelect: false,
                                required: property.required,
                            }
                            return (
                                <FormField
                                    key={property.propertyId}
                                    questionNum={index + 1}
                                    field={field}
                                    handleInputChange={onPropertyChange}
                                    mb={8}
                                />
                            );
                        })}
                        <FormControl isRequired>
                            <FormLabel>{"Date & Time of recording"}</FormLabel>
                            <Input
                                width={"full"}
                                type='datetime-local'
                                onChange={(e) => { setRecordedDate(new Date(e.target.value)) }}
                                value={convDateTimeToISO(recordedDate)}
                            />
                        </FormControl>
                        <Button mt="4" type="submit" width={"full"}>
                            Submit
                        </Button>
                    </Box>
                </Container>
            </VStack>
        </>
    )
}

const ThankYouSection: FC = () => {
    const navigate = useNavigate();
    const { clientId } = useParams();
    return (
        <Center mt={20} w={"full"}>
            <VStack>
                <Logo />
                <Heading as="h1" size="lg">Thank you!</Heading>
                <Text>One step goes a long way</Text>
                <Divider m={2} color={"blue.900"} />
                <HStack>
                    <Button
                        onClick={() => { navigate(`${ROUTES.CLIENTS}/${clientId}${ROUTES.VIEW_METRIC_DATA}`) }}
                    >
                        View Trends
                    </Button>
                    <Button
                        onClick={() => { navigate(`${ROUTES.CLIENTS}/${clientId}${ROUTES.CLIENT_PASS}`) }}
                    >
                        Record More
                    </Button>
                </HStack>
            </VStack>
        </Center>
    )
}

export default MetricForm;
