import { Frequency, RRule, Weekday } from "rrule";

type FrequencyOption = {
  value: Frequency;
  name: string;
};

type DayOption = {
  value: Weekday;
  name: string;
};

export const frequencyOptions: FrequencyOption[] = [
  { value: RRule.DAILY, name: "Day" },
  { value: RRule.WEEKLY, name: "Week" },
  // { value: RRule.MONTHLY, name: "Month" },
];

export const dayOptions: DayOption[] = [
  {
    value: RRule.MO,
    name: "Monday",
  },
  {
    value: RRule.TU,
    name: "Tuesday",
  },
  {
    value: RRule.WE,
    name: "Wednesday",
  },
  {
    value: RRule.TH,
    name: "Thursday",
  },
  {
    value: RRule.FR,
    name: "Friday",
  },
  {
    value: RRule.SA,
    name: "Saturday",
  },
  {
    value: RRule.SU,
    name: "Sunday",
  },
];

export const hours = Array.from({ length: 24 }, (_, index) => index);

export const formatHourStr = (hour: number): string => {
  const formattedHour = hour % 12 || 12;
  const period = hour < 12 ? "AM" : "PM";
  return `${formattedHour}:00 ${period}`;
};

export const formatHourShortStr = (hour: number): string => {
  const formattedHour = hour % 12 || 12;
  const period = hour < 12 ? "AM" : "PM";
  return `${formattedHour}:00${period}`;
};

export const DEFAULT_SELECTED_HOUR = 9;

// This function converts UTC time to local time and adjusts the day if necessary
const convertUtcTimeToLocal = (
  utcHour: number,
  utcDay: Weekday
): { localTime: string; localDay: string | undefined } => {
  // Create a Date object for the current date at the specified UTC hour
  const now = new Date();
  const utcDate = new Date(
    Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      utcHour,
      0,
      0
    )
  );

  // Convert UTC date to local date
  const localDate = new Date(
    utcDate.toLocaleString("en-US", {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    })
  );

  // Determine the day difference between UTC and local time
  const dayDiff = localDate.getDate() - now.getUTCDate();

  // Adjust the day of week based on the day difference
  let adjustedDay = (utcDay.weekday + dayDiff) % 7;
  adjustedDay = adjustedDay < 0 ? 7 + adjustedDay : adjustedDay; // Correct negative values

  // Format the local time as a string
  const localTime = localDate.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });

  // Find the local day name
  const localDayName = dayOptions.find(
    (option) => option.value.weekday === adjustedDay
  )?.name;

  return { localTime, localDay: localDayName };
};

// only supports DAILY and WEEKLY frequencies
export function getReadableRRule(rrule: string): string {
  const attributes: Record<string, string> = {};
  try {
    const attrArray = rrule.replace("RRULE:", "").split(/[=;]/);

    for (let i = 0; i < attrArray.length; i += 2) {
      attributes[attrArray[i]] = attrArray[i + 1];
    }

    const freq = attributes["FREQ"];
    const byHour = parseInt(attributes["BYHOUR"], 10);

    if (freq === "DAILY") {
      // Day doesn't matter for daily
      const { localTime } = convertUtcTimeToLocal(byHour, new Weekday(0));
      return `Every day at ${localTime}`;
    } else if (freq === "WEEKLY") {
      const wkst = parseInt(attributes["WKST"], 10);
      const { localTime, localDay } = convertUtcTimeToLocal(
        byHour,
        new Weekday(wkst)
      );
      return `Every week on ${localDay} at ${localTime}`;
    } else {
      return "Unsupported frequency";
    }
  } catch (e) {
    console.error(e);
    return "Invalid RRULE";
  }
}

export enum TimePeriod {
  PAST_DAY = "PAST_DAY",
  PAST_WEEK = "PAST_WEEK",
  PAST_2_WEEKS = "PAST_2_WEEKS",
  PAST_MONTH = "PAST_MONTH",
  ALL_TIME = "ALL_TIME",
}

export const HOUR = 60 * 60 * 1000; // in ms
export const DAY = 24 * HOUR; // in ms

export const TimePeriodToTimeQuanta: Record<TimePeriod, number> = {
  PAST_DAY: HOUR,
  PAST_WEEK: DAY,
  PAST_2_WEEKS: DAY,
  PAST_MONTH: DAY,
  ALL_TIME: DAY, // unused for now
}


export const getFirstDate = (selectedTimePeriod: TimePeriod, endTime: number) => {
  const endDate = new Date(endTime);
  switch (selectedTimePeriod) {
    case TimePeriod.PAST_DAY:
      return new Date(endDate.setDate(endDate.getDate() - 1));
    case TimePeriod.PAST_WEEK:
      return new Date(endDate.setDate(endDate.getDate() - 7));
    case (TimePeriod.PAST_2_WEEKS):
      return new Date(endDate.setDate(endDate.getDate() - 14));
    case TimePeriod.PAST_MONTH:
      return new Date(endDate.setMonth(endDate.getMonth() - 1));
    case TimePeriod.ALL_TIME:
      return new Date(0); // 01-01-1970
    default:
      return new Date(0); // 01-01-1970
  }
};

export const getEndOfDayTime = (date: Date): number => {
  date.setHours(23, 59, 59, 999);
  return date.getTime();
}

export const getDateLabelFromTimestamp = (timestamp: number): string => {
  const date = new Date(timestamp);
  const day = date.getDate().toString().padStart(2, "0"); // Ensure two digits for day
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Ensure two digits for month

  return `${day}/${month}`;
}

export const getHourLabelFromTimestamp = (timestamp: number): string => {
  const date = new Date(timestamp);
  return `${formatHourShortStr(date.getHours())}`;
}

export const TimeQunataToLabelFunc: Record<number, (timestamp: number) => string> = {
  [DAY]: getDateLabelFromTimestamp,
  [HOUR]: getHourLabelFromTimestamp,
}


export const getAllLabels = (selectedTimePeriod: TimePeriod, endTime: number): string[] => {
  const labels: string[] = [];
  const firstDate = getFirstDate(selectedTimePeriod, endTime);
  const timeQuanta = TimePeriodToTimeQuanta[selectedTimePeriod];

  for (let i = firstDate.getTime() + timeQuanta; i < endTime + timeQuanta; i += timeQuanta) {
    const timestampToLabelFunc = TimeQunataToLabelFunc[timeQuanta];
    labels.push(timestampToLabelFunc(i));
  }
  return labels;
}
