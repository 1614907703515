import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Text,
  useToast,
  VStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  Select,
  Tag,
  HStack,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Input,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { theme } from "../../constants";
import {
  getAllStaffList,
  updateStaff,
  deleteStaff,
  registerStaff,
} from "../../api/staff";
import { StaffDto, StaffAuthLevel, CreateStaffDto } from "../../types/staff";
import { getOrganizations } from "../../api/organization";
import { OrganizationDto } from "../../types/organization";
import { FaTrash, FaEdit, FaPlus } from "react-icons/fa";

const initialCreateForm: CreateStaffDto = {
  name: "",
  email: "",
  password: "",
  organizationId: undefined,
  authLevel: StaffAuthLevel.BASIC,
};

export const StaffTab = () => {
  const [staff, setStaff] = useState<StaffDto[]>([]);
  const [organizations, setOrganizations] = useState<OrganizationDto[]>([]);
  const [selectedOrgFilter, setSelectedOrgFilter] = useState<string>("all");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState<StaffDto | null>(null);
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const {
    isOpen: isCreateOpen,
    onOpen: onCreateOpen,
    onClose: onCreateClose,
  } = useDisclosure();
  const [createForm, setCreateForm] =
    useState<CreateStaffDto>(initialCreateForm);
  const [isCreating, setIsCreating] = useState(false);
  const toast = useToast();

  useEffect(() => {
    fetchStaff();
    fetchOrganizations();
  }, []);

  const fetchStaff = async () => {
    setIsLoading(true);
    try {
      const response = await getAllStaffList();
      if (response.data) {
        // Fetch organizations if not already loaded
        if (organizations.length === 0) {
          const orgs = await getOrganizations();
          if ("data" in orgs && orgs.data) {
            setOrganizations(orgs.data);
            // Map staff with organization names
            const staffWithOrgs = response.data.map((member) => ({
              ...member,
              organizationName:
                orgs.data!.find((org) => org.id === member.organizationId)
                  ?.name || "No Organization",
            }));
            setStaff(staffWithOrgs);
            return;
          }
        }
        // Use existing organizations list
        const staffWithOrgs = response.data.map((member) => ({
          ...member,
          organizationName:
            organizations.find((org) => org.id === member.organizationId)
              ?.name || "No Organization",
        }));
        setStaff(staffWithOrgs);
      }
    } catch (error) {
      console.error("Error fetching staff:", error);
      toast({
        title: "Error fetching staff",
        status: "error",
        duration: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchOrganizations = async () => {
    try {
      const orgs = await getOrganizations();
      if ("data" in orgs && orgs.data) {
        setOrganizations(orgs.data);
      }
    } catch (error) {
      console.error("Error fetching organizations:", error);
    }
  };

  const handleUpdateStaff = async () => {
    if (!selectedStaff) return;
    try {
      const updateData = {
        organizationId: selectedStaff.organizationId,
        authLevel: selectedStaff.authLevel || StaffAuthLevel.BASIC,
        // Keep existing data
        name: selectedStaff.name,
        email: selectedStaff.email,
      };

      console.log("Updating staff with data:", updateData);
      await updateStaff(selectedStaff.id, updateData);

      toast({
        title: "Staff updated successfully",
        status: "success",
        duration: 3000,
      });
      await fetchStaff(); // Refresh the list
      onEditClose();
    } catch (error) {
      console.error("Error updating staff:", error);
      toast({
        title: "Error updating staff",
        description:
          error instanceof Error ? error.message : "Unknown error occurred",
        status: "error",
        duration: 3000,
      });
    }
  };

  const handleDeleteStaff = async () => {
    if (!selectedStaff) return;
    try {
      await deleteStaff(selectedStaff.id);
      toast({
        title: "Staff removed successfully",
        status: "success",
        duration: 3000,
      });
      fetchStaff();
      onDeleteClose();
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || "";
      let userMessage = "Unable to remove staff member. ";

      if (errorMessage.includes("form")) {
        userMessage +=
          "This staff member has associated forms. Please reassign or delete these forms first.";
      } else if (errorMessage.includes("organization")) {
        userMessage += "Please remove them from their organization first.";
      } else {
        userMessage +=
          "They might have active connections to forms or organizations.";
      }

      toast({
        title: "Error removing staff",
        description: userMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleCreateInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setCreateForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCreateStaff = async () => {
    setIsCreating(true);
    try {
      await registerStaff(createForm);
      toast({
        title: "Staff registered successfully",
        status: "success",
        duration: 3000,
      });
      setCreateForm(initialCreateForm);
      fetchStaff();
      onCreateClose();
    } catch (error: any) {
      toast({
        title: "Error registering staff",
        description:
          error.response?.data?.message || "Could not register staff member",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsCreating(false);
    }
  };

  const getAuthLevelColor = (level?: StaffAuthLevel) => {
    switch (level) {
      case StaffAuthLevel.ADMIN:
        return "red";
      case StaffAuthLevel.BASIC:
      default:
        return "blue";
    }
  };

  const filteredStaff = staff.filter((member) => {
    if (selectedOrgFilter === "all") return true;
    if (selectedOrgFilter === "none") return !member.organizationId;
    return member.organizationId === selectedOrgFilter;
  });

  return (
    <VStack spacing={6} align="stretch">
      <Box>
        <HStack justify="space-between" mb={4}>
          <Text fontSize="lg" fontWeight="semibold" color={`${theme}.800`}>
            Staff Members
          </Text>
          <Button leftIcon={<FaPlus />} onClick={onCreateOpen}>
            Create Staff
          </Button>
        </HStack>

        <FormControl mb={4}>
          <HStack>
            <FormLabel mb={0} minW="120px">
              Filter by Organization:
            </FormLabel>
            <Select
              value={selectedOrgFilter}
              onChange={(e) => setSelectedOrgFilter(e.target.value)}
              maxW="400px"
            >
              <option value="all">All Organizations</option>
              <option value="none">No Organization</option>
              {organizations.map((org) => (
                <option key={org.id} value={org.id}>
                  {org.name}
                </option>
              ))}
            </Select>
          </HStack>
        </FormControl>

        {isLoading ? (
          <Spinner />
        ) : staff.length === 0 ? (
          <Text color="gray.500">No staff members found</Text>
        ) : (
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Email</Th>
                <Th>Organization</Th>
                <Th>Auth Level</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredStaff
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((member) => (
                  <Tr
                    key={member.id}
                    bg={!member.organizationId ? "orange.50" : undefined}
                    borderLeft={
                      !member.organizationId ? "4px solid" : undefined
                    }
                    borderLeftColor={
                      !member.organizationId ? "orange.200" : undefined
                    }
                  >
                    <Td>{member.name}</Td>
                    <Td>{member.email}</Td>
                    <Td>
                      {!member.organizationId ? (
                        <Text color="orange.500" fontWeight="medium">
                          No Organization
                        </Text>
                      ) : (
                        member.organizationName
                      )}
                    </Td>
                    <Td>
                      <Tag
                        colorScheme={getAuthLevelColor(member.authLevel)}
                        size="md"
                      >
                        {member.authLevel || StaffAuthLevel.BASIC}
                      </Tag>
                    </Td>
                    <Td>
                      <HStack spacing={2}>
                        <IconButton
                          aria-label="Edit staff"
                          icon={<FaEdit />}
                          size="sm"
                          onClick={() => {
                            setSelectedStaff(member);
                            onEditOpen();
                          }}
                        />
                        <IconButton
                          aria-label="Remove staff"
                          icon={<FaTrash />}
                          size="sm"
                          colorScheme="red"
                          onClick={() => {
                            setSelectedStaff(member);
                            onDeleteOpen();
                          }}
                        />
                      </HStack>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        )}
      </Box>

      <Modal isOpen={isDeleteOpen} onClose={onDeleteClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Staff Removal</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack align="stretch" spacing={4}>
              <Text>
                Are you sure you want to remove {selectedStaff?.name}?
              </Text>
              {selectedStaff?.organizationId && (
                <Text color="orange.500">
                  Warning: This staff member is connected to an organization (
                  {selectedStaff.organizationName}). You'll need to remove them
                  from the organization first.
                </Text>
              )}
              <Text color="orange.500">
                Note: If this staff member has created or is associated with any
                forms, you'll need to reassign or delete those forms first.
              </Text>
              <Text fontSize="sm" color="gray.500">
                This action cannot be undone.
              </Text>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onDeleteClose}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={handleDeleteStaff}>
              Remove Staff
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isEditOpen} onClose={onEditClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Staff Member</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedStaff && (
              <VStack spacing={4}>
                <FormControl>
                  <FormLabel>Organization</FormLabel>
                  <Select
                    value={selectedStaff.organizationId}
                    onChange={(e) =>
                      setSelectedStaff({
                        ...selectedStaff,
                        organizationId: e.target.value,
                      })
                    }
                  >
                    <option value="">Select Organization</option>
                    {organizations.map((org) => (
                      <option key={org.id} value={org.id}>
                        {org.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                <FormControl>
                  <FormLabel>Auth Level</FormLabel>
                  <Select
                    value={selectedStaff.authLevel || StaffAuthLevel.BASIC}
                    onChange={(e) =>
                      setSelectedStaff({
                        ...selectedStaff,
                        authLevel: e.target.value as StaffAuthLevel,
                      })
                    }
                  >
                    <option value={StaffAuthLevel.BASIC}>Basic</option>
                    <option value={StaffAuthLevel.ADMIN}>Admin</option>
                  </Select>
                </FormControl>
              </VStack>
            )}
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onEditClose}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={handleUpdateStaff}>
              Save Changes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isCreateOpen} onClose={onCreateClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create New Staff</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <FormControl isRequired>
                <FormLabel>Name</FormLabel>
                <Input
                  name="name"
                  value={createForm.name}
                  onChange={handleCreateInputChange}
                  placeholder="Enter staff name"
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Email</FormLabel>
                <Input
                  name="email"
                  type="email"
                  value={createForm.email}
                  onChange={handleCreateInputChange}
                  placeholder="Enter staff email"
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Password</FormLabel>
                <Input
                  name="password"
                  type="password"
                  value={createForm.password}
                  onChange={handleCreateInputChange}
                  placeholder="Enter password"
                />
              </FormControl>

              <FormControl>
                <FormLabel>Organization (Optional)</FormLabel>
                <Select
                  name="organizationId"
                  value={createForm.organizationId || ""}
                  onChange={handleCreateInputChange}
                >
                  <option value="">No Organization</option>
                  {organizations.map((org) => (
                    <option key={org.id} value={org.id}>
                      {org.name}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel>Auth Level</FormLabel>
                <Select
                  name="authLevel"
                  value={createForm.authLevel}
                  onChange={handleCreateInputChange}
                >
                  <option value={StaffAuthLevel.BASIC}>Basic</option>
                  <option value={StaffAuthLevel.ADMIN}>Admin</option>
                </Select>
              </FormControl>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onCreateClose}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleCreateStaff}
              isLoading={isCreating}
            >
              Create
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};
