import axios from "./axios/staffAuth";
import {
  StaffDto,
  GetStaffListResp,
  CreateStaffDto,
  UpdateStaffDto,
  StaffAuthLevel,
} from "../types/staff";

const API_URL = process.env.REACT_APP_API_URL || "";
const BATCH_SIZE = 100;

export const registerStaff = async (data: CreateStaffDto): Promise<StaffDto> => {
  const registerData = {
    ...data,
    authLevel: data.authLevel || StaffAuthLevel.BASIC,
  };
  const response = await axios.post(`${API_URL}/auth/register`, registerData);
  return response.data;
};

export const getStaffById = async (id: string): Promise<StaffDto> => {
  const response = await axios.get(`${API_URL}/staff/${id}`);
  return response.data;
};

export const createStaff = async (data: CreateStaffDto): Promise<StaffDto> => {
  const response = await axios.post(`${API_URL}/staff`, data);
  return response.data;
};

export const updateStaff = async (
  id: string,
  data: UpdateStaffDto
): Promise<StaffDto> => {
  try {
    const response = await axios.put(`${API_URL}/staff/${id}`, data);
    return response.data;
  } catch (error) {
    console.error("Update staff error:", error);
    throw error;
  }
};

export const deleteStaff = async (id: string): Promise<void> => {
  await axios.delete(`${API_URL}/staff/${id}`);
};

export const getAllStaffList = async (
  organizationId: string = ""
): Promise<GetStaffListResp> => {
  try {
    let params = { size: BATCH_SIZE, organizationId };
    let res = await axios.get<GetStaffListResp>(`${API_URL}/staff`, { params });
    let remainingStaff = await getRemainingStaff(res.data?.total || 0);
    if (remainingStaff.message) {
      return {
        data: [],
        total: 0,
        message: remainingStaff.message,
      };
    }
    res.data?.data?.concat(remainingStaff.data || []);
    return res.data;
  } catch (err: any) {
    return {
      data: [],
      total: 0,
      message: err.response ? err.response.data.message : err.message,
    };
  }
};

async function getRemainingStaff(total: number): Promise<GetStaffListResp> {
  try {
    let staffList: StaffDto[] = [];
    for (let i = 1; i * BATCH_SIZE < total; i += 1) {
      let res = await axios.get<GetStaffListResp>(`${API_URL}/staff`, {
        params: { size: BATCH_SIZE, page: i },
      });
      staffList.concat(res.data?.data || []);
    }
    return {
      data: staffList,
      total: staffList.length,
    };
  } catch (err: any) {
    return {
      data: [],
      total: 0,
      message: err.response ? err.response.data.message : err.message,
    };
  }
}
