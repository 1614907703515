import { Property } from "../types/protocol";
import {
  CreateFormDto,
  OptionsLayout,
  ResponseType,
  FormTypeField,
  FormTemplateId,
} from "../types/form";

// export const BP_WORKFLOW_FIELDS: FormTypeField[] = [
//   {
//     label: "Systolic mm hg",
//     isMultiSelect: false,
//     key: "q_1",
//     question: "What is your Upper Range?",
//     responseType: ResponseType.PROPERTY,
//     property: Property.BLOOD_PRESSURE_SYSTOLIC,
//     numberOptions: {
//       min: 0,
//       max: 400,
//     },
//     required: true,
//   },
//   {
//     label: "Diastolic mm hg",
//     isMultiSelect: false,
//     key: "q_2",
//     question: "What is your Lower Range?",
//     responseType: ResponseType.PROPERTY,
//     property: Property.BLOOD_PRESSURE_DIASTOLIC,
//     numberOptions: {
//       min: 0,
//       max: 400,
//     },
//     required: true,
//   },
// ];

// export const bpForm: CreateFormDto = {
//   fields: BP_WORKFLOW_FIELDS,
//   message:
//     "Hi ${name}! Time for a quick health check-in 🩺💓 Please take a moment to log your daily blood pressure readings. Your well-being matters, and tracking helps us ensure you're on the path to a healthy heart! 🩸📊",
//   name: "Blood Pressure Tracker",
//   description: "Monitoring of systolic and diastolic readings",
//   postSubmitMsg: {
//     header: "Thank you for submitting your blood pressure readings!",
//     body: "Your health is important to us. Keep up the good work! 🩸💪",
//   },
//   templateId: FormTemplateId.BLOOD_PRESSURE
// };

const PAM_FORM_FIELDS: FormTypeField[] = [
  {
    key: "q_1",
    label: "Personal responsibility for health",
    question: "When all is said and done, I am the person who is responsible for taking care of my health.",
    responseType: ResponseType.RADIO,
    options: {
      "1": "Disagree Strongly",
      "2": "Disagree",
      "3": "Agree",
      "4": "Agree Strongly",
      "5": "N/A",
    },
    isMultiSelect: false,
    required: true,
    layout: OptionsLayout.HORIZONTAL,
  },
  {
    key: "q_2",
    label: "Active role in health care",
    question: "Taking an active role in my own health care is the most important thing that affects my health.",
    responseType: ResponseType.RADIO,
    options: {
      "1": "Disagree Strongly",
      "2": "Disagree",
      "3": "Agree",
      "4": "Agree Strongly",
      "5": "N/A",
    },
    isMultiSelect: false,
    required: true,
    layout: OptionsLayout.HORIZONTAL,
  },
  {
    key: "q_3",
    label: "Knowledge of medications",
    question: "I know what each of my prescribed medications do.",
    responseType: ResponseType.RADIO,
    options: {
      "1": "Disagree Strongly",
      "2": "Disagree",
      "3": "Agree",
      "4": "Agree Strongly",
      "5": "N/A",
    },
    isMultiSelect: false,
    required: true,
    layout: OptionsLayout.HORIZONTAL,
  },
  {
    key: "q_4",
    label: "Confidence in deciding medical needs",
    question: "I am confident that I can tell whether I need to go to the doctor or whether I can take care of a health problem myself.",
    responseType: ResponseType.RADIO,
    options: {
      "1": "Disagree Strongly",
      "2": "Disagree",
      "3": "Agree",
      "4": "Agree Strongly",
      "5": "N/A",
    },
    isMultiSelect: false,
    required: true,
    layout: OptionsLayout.HORIZONTAL,
  },
  {
    key: "q_5",
    label: "Confidence in communicating with doctor",
    question: "I am confident that I can tell a doctor concerns I have even when he or she does not ask.",
    responseType: ResponseType.RADIO,
    options: {
      "1": "Disagree Strongly",
      "2": "Disagree",
      "3": "Agree",
      "4": "Agree Strongly",
      "5": "N/A",
    },
    isMultiSelect: false,
    required: true,
    layout: OptionsLayout.HORIZONTAL,
  },
  {
    key: "q_6",
    label: "Confidence in following treatments",
    question: "I am confident that I can follow through on medical treatments I may need to do at home.",
    responseType: ResponseType.RADIO,
    options: {
      "1": "Disagree Strongly",
      "2": "Disagree",
      "3": "Agree",
      "4": "Agree Strongly",
      "5": "N/A",
    },
    isMultiSelect: false,
    required: true,
    layout: OptionsLayout.HORIZONTAL,
  },
  {
    key: "q_7",
    label: "Maintaining lifestyle changes",
    question: "I have been able to maintain (keep up with) lifestyle changes, like eating right or exercising.",
    responseType: ResponseType.RADIO,
    options: {
      "1": "Disagree Strongly",
      "2": "Disagree",
      "3": "Agree",
      "4": "Agree Strongly",
      "5": "N/A",
    },
    isMultiSelect: false,
    required: true,
    layout: OptionsLayout.HORIZONTAL,
  },
  {
    key: "q_8",
    label: "Preventing health problems",
    question: "I know how to prevent problems with my health.",
    responseType: ResponseType.RADIO,
    options: {
      "1": "Disagree Strongly",
      "2": "Disagree",
      "3": "Agree",
      "4": "Agree Strongly",
      "5": "N/A",
    },
    isMultiSelect: false,
    required: true,
    layout: OptionsLayout.HORIZONTAL,
  },
  {
    key: "q_9",
    label: "Confidence in solving new problems",
    question: "I am confident I can figure out solutions when new problems arise with my health.",
    responseType: ResponseType.RADIO,
    options: {
      "1": "Disagree Strongly",
      "2": "Disagree",
      "3": "Agree",
      "4": "Agree Strongly",
      "5": "N/A",
    },
    isMultiSelect: false,
    required: true,
    layout: OptionsLayout.HORIZONTAL,
  },
  {
    key: "q_10",
    label: "Maintaining lifestyle changes during stress",
    question: "I am confident that I can maintain lifestyle changes, like eating right and exercising, even during times of stress.",
    responseType: ResponseType.RADIO,
    options: {
      "1": "Disagree Strongly",
      "2": "Disagree",
      "3": "Agree",
      "4": "Agree Strongly",
      "5": "N/A",
    },
    isMultiSelect: false,
    required: true,
    layout: OptionsLayout.HORIZONTAL,
  },
];

const patientActivationMeasureForm: CreateFormDto = {
  fields: PAM_FORM_FIELDS,
  name: "Patient Activation Measure",
  description: "Assessment of health management confidence and activation.",
  message:
    "Hi ${name}! We'd love your input to better support your health journey. Please take a few minutes to complete this quick survey. Your responses will help us understand how we can empower you to take charge of your well-being! 💪✨",
  postSubmitMsg: {
    body: "Thanks for submitting, have a nice day!",
    header: "Thank you!",
  },
  templateId: FormTemplateId.PATIENT_ACTIVATION_MEASURE
}

const EQ5D_FORM_FIELDS: FormTypeField[] = [
  {
    key: "q_1",
    label: "Mobility",
    question: "Please describe your mobility:",
    responseType: ResponseType.RADIO,
    options: {
      "1": "I have no problems in walking about",
      "2": "I have slight problems in walking about",
      "3": "I have moderate problems in walking about",
      "4": "I have severe problems in walking about",
      "5": "I am unable to walk about",
    },
    isMultiSelect: false,
    required: true,
    layout: OptionsLayout.VERTICAL,
  },
  {
    key: "q_2",
    label: "Self-Care",
    question: "Please describe your ability to wash or dress yourself:",
    responseType: ResponseType.RADIO,
    options: {
      "1": "I have no problems washing or dressing myself",
      "2": "I have slight problems washing or dressing myself",
      "3": "I have moderate problems washing or dressing myself",
      "4": "I have severe problems washing or dressing myself",
      "5": "I am unable to wash or dress myself",
    },
    isMultiSelect: false,
    required: true,
    layout: OptionsLayout.VERTICAL,
  },
  {
    key: "q_3",
    label: "Usual Activities",
    question: "Please describe your ability to perform usual activities (e.g., work, study, housework, family, or leisure activities):",
    responseType: ResponseType.RADIO,
    options: {
      "1": "I have no problems doing my usual activities",
      "2": "I have slight problems doing my usual activities",
      "3": "I have moderate problems doing my usual activities",
      "4": "I have severe problems doing my usual activities",
      "5": "I am unable to do my usual activities",
    },
    isMultiSelect: false,
    required: true,
    layout: OptionsLayout.VERTICAL,
  },
  {
    key: "q_4",
    label: "Pain/Discomfort",
    question: "Please describe your level of pain or discomfort:",
    responseType: ResponseType.RADIO,
    options: {
      "1": "I have no pain or discomfort",
      "2": "I have slight pain or discomfort",
      "3": "I have moderate pain or discomfort",
      "4": "I have severe pain or discomfort",
      "5": "I have extreme pain or discomfort",
    },
    isMultiSelect: false,
    required: true,
    layout: OptionsLayout.VERTICAL,
  },
  {
    key: "q_5",
    label: "Anxiety/Depression",
    question: "Please describe your level of anxiety or depression:",
    responseType: ResponseType.RADIO,
    options: {
      "1": "I am not anxious or depressed",
      "2": "I am slightly anxious or depressed",
      "3": "I am moderately anxious or depressed",
      "4": "I am severely anxious or depressed",
      "5": "I am extremely anxious or depressed",
    },
    isMultiSelect: false,
    required: true,
    layout: OptionsLayout.VERTICAL,
  },
  {
    key: "q_6",
    label: "Health Today",
    question: "Please rate your health today on a scale from 0 (worst health) to 10 (best health):",
    responseType: ResponseType.LINEAR_SCALE,
    isMultiSelect: false,
    required: true,
  },
];

const eq5dForm: CreateFormDto = {
  fields: EQ5D_FORM_FIELDS,
  name: "EQ-5D",
  description: "Evaluation of mobility, self-care, and overall health status.",
  message:
    "Hi ${name}! We're checking in to understand your overall health and well-being better. Please take a moment to complete this short questionnaire. Your input helps us tailor care to your needs and ensure the best outcomes for you! 🌟💙",
  postSubmitMsg: {
    body: "Thanks for submitting, have a nice day!",
    header: "Thank you!",
  },
  templateId: FormTemplateId.PATIENT_ACTIVATION_MEASURE
}

export type FormTemplate = {
  formTemplateDto: CreateFormDto;
};

export const formTemplates: Record<FormTemplateId, FormTemplate> = {
  CUSTOM: {
    formTemplateDto: {
      fields: [],
      message: "",
      name: "Untitled",
      description: "Add a form description",
      postSubmitMsg: {
        body: "Thanks for submitting, have a nice day!",
        header: "Thank you!",
      },
      templateId: FormTemplateId.CUSTOM
    },
  },
  PATIENT_ACTIVATION_MEASURE: {
    formTemplateDto: patientActivationMeasureForm
  },
  EQ_5D: {
    formTemplateDto: eq5dForm
  }
};
