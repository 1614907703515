import {
  Box,
  HStack,
  Text,
  Switch,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  useColorModeValue,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { getOrganizations } from "../../api/organization";
import { updateStaff } from "../../api/staff";
import { OrganizationDto } from "../../types/organization";
import { useStaffStore } from "../../store/staffStore";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";

export const OrgToggle = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [linkedOrg, setLinkedOrg] = useState<OrganizationDto | null>(null);
  const { currentStaff, logout } = useStaffStore();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [pendingToggle, setPendingToggle] = useState(false);

  useEffect(() => {
    fetchLinkedOrg();
  }, [currentStaff?.organizationId]);

  const fetchLinkedOrg = async () => {
    if (!currentStaff?.organizationId) return;

    try {
      const orgs = await getOrganizations();
      if (!orgs.data) return;

      const currentOrg = orgs.data.find(
        (org) => org.id === currentStaff.organizationId
      );
      if (!currentOrg) return;

      const linkedOrgId = currentOrg.isTestOrg
        ? currentOrg.productionOrgId
        : currentOrg.testOrgId;
      const linkedOrg = orgs.data.find((org) => org.id === linkedOrgId);
      setLinkedOrg(linkedOrg || null);
    } catch (error) {
      console.error("Error fetching organizations:", error);
    }
  };

  const handleToggleConfirm = async () => {
    if (!currentStaff || !linkedOrg) return;

    setLoading(true);
    try {
      const newOrgId = linkedOrg.id;
      const targetEnvironment =
        currentStaff.organizationId === linkedOrg.testOrgId ? "Live" : "Test";

      await updateStaff(currentStaff.id, {
        ...currentStaff,
        organizationId: newOrgId,
      });

      sessionStorage.setItem("lastEmail", currentStaff.email);
      sessionStorage.setItem("orgSwitch", "true");

      toast({
        title: "Mode Switch",
        description: `Switched to ${targetEnvironment} Mode. Please log in again.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      logout();
      navigate(ROUTES.LOGIN);
    } catch (error) {
      toast({
        title: "Failed to switch mode",
        description: "An error occurred while switching modes",
        status: "error",
        duration: 3000,
      });
      setLoading(false);
    }
    onClose();
  };

  const handleToggleClick = () => {
    setPendingToggle(true);
    onOpen();
  };

  const handleCancelToggle = () => {
    setPendingToggle(false);
    onClose();
  };

  if (!linkedOrg || !currentStaff) return null;

  const isTestEnvironment = currentStaff.organizationId === linkedOrg.testOrgId;
  const targetEnvironment = isTestEnvironment ? "Live" : "Test";

  return (
    <>
      <Box bg={isTestEnvironment ? "yellow.100" : "gray.100"} px={6} py={2}>
        <HStack justify="space-between" align="center">
          <Text
            fontSize="sm"
            fontWeight="medium"
            color={isTestEnvironment ? "yellow.800" : "blue.900"}
          >
            {isTestEnvironment ? "Test Mode" : "Live Mode"}
          </Text>
          <Switch
            isChecked={!isTestEnvironment}
            onChange={handleToggleClick}
            isDisabled={loading}
            sx={{
              "span.chakra-switch__track[data-checked]": {
                backgroundColor: "blue.900",
              },
            }}
            size="sm"
          />
        </HStack>
      </Box>

      <Modal isOpen={isOpen} onClose={handleCancelToggle}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Mode Switch</ModalHeader>
          <ModalBody>
            Are you sure you want to switch to{" "}
            <Text as="span" fontWeight="bold">
              {targetEnvironment} Mode
            </Text>
            ? You will need to log in again.
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={handleCancelToggle}>
              Cancel
            </Button>
            <Button
              colorScheme={targetEnvironment === "Live" ? "blue" : "yellow"}
              onClick={handleToggleConfirm}
              isLoading={loading}
            >
              Switch to {targetEnvironment} Mode
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
