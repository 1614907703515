import { DataPoint } from "../components/Graph";
import { PatientMetricDto } from "../types/form";

export function patientMetricDtoToDataPointsGroupedByTime(metrics: PatientMetricDto[]): DataPoint[][] {
    const metricsByTime = groupPatientMetricDtoByTime(metrics);
    return groupedPatientMetricDtoToDataPoints(metricsByTime);
}

export function groupedPatientMetricDtoToDataPoints(metrics: PatientMetricDto[][]): DataPoint[][] {
    return metrics.map((metrics) => {
        const dataPoints = metrics.map((metric) => {
            return {
                propertyId: metric.propertyId,
                value: metric.value,
                timestamp: parseInt(metric.timestamp),
            };
        });
        return dataPoints;
    });
}

export function groupPatientMetricDtoByTime(metrics: PatientMetricDto[]): PatientMetricDto[][] {
    const timeToDataPoints: Record<string, PatientMetricDto[]> = {};
    metrics.forEach((metric) => {
        const timestamp = parseInt(metric.timestamp);
        if (!timeToDataPoints[timestamp]) {
            timeToDataPoints[timestamp] = [];
        }
        timeToDataPoints[timestamp].push(metric);
    });

    return Object.entries(timeToDataPoints) //TODO: move this sort logic to backend
        .sort((a, b) => parseInt(a[0]) - parseInt(b[0])) // sorting by timestamp
        .map(([, value]) => value)
}
