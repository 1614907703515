import {
  Avatar,
  Box,
  BoxProps,
  Button,
  Divider,
  Flex,
  Text,
  useColorModeValue,
  VStack,
  Tag,
} from "@chakra-ui/react";
import { IconType } from "react-icons";
import { FaSignOutAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { ROUTES, theme } from "../../constants";
import { Logo } from "../../Logo";
import { useStaffStore } from "../../store/staffStore";
import NavItem from "./NavItem";
import {
  AiOutlineForm,
  AiOutlineSync,
  AiOutlineTeam,
  AiOutlineSetting,
} from "react-icons/ai";
import { useMemo } from "react";
import { StaffAuthLevel } from "../../types/staff";
import { OrgToggle } from "../OrgToggle";

interface LinkItemProps {
  name: string;
  path: string;
  icon: IconType;
}

const LinkItems: Array<LinkItemProps> = [
  { name: "Clients", path: ROUTES.CLIENTS, icon: AiOutlineTeam },
  { name: "Forms", path: ROUTES.FORMS_LIST, icon: AiOutlineForm },
  { name: "Protocols", path: ROUTES.PROTOCOLS, icon: AiOutlineSync },
];

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const { currentStaff, logout } = useStaffStore();
  const isAdmin = currentStaff?.authLevel === StaffAuthLevel.ADMIN;

  const handleSignOut = () => {
    logout();
  };

  return (
    <Box
      bg={useColorModeValue("#ffffff", "gray.900")}
      borderRight="2px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <OrgToggle />

      <Box px={6} py={4}>
        <Logo />
      </Box>

      <Divider borderColor="gray.200" />

      <Box>
        {LinkItems.map((link) => (
          <Link to={link.path} key={link.name} onClick={onClose}>
            <NavItem icon={link.icon}>{link.name}</NavItem>
          </Link>
        ))}
      </Box>

      <Box position="absolute" bottom="0" w="100%" p={4}>
        <VStack align="stretch" spacing={4}>
          <Box>
            <Text
              fontSize="xs"
              fontWeight="semibold"
              fontFamily="heading"
              color={`${theme}.800`}
              textTransform="uppercase"
              mb={2}
            >
              {currentStaff?.organizationName}
            </Text>
            <Flex alignItems="center">
              <Avatar name={currentStaff?.name || ""} size="sm" mr={2} />
              <VStack alignItems="flex-start" spacing={0}>
                <Text fontWeight="semibold" fontFamily="heading">
                  {currentStaff?.name || "Loading..."}
                </Text>
                <Text fontSize="xs" fontFamily="heading" color="gray.500">
                  {currentStaff?.email || ""}
                </Text>
              </VStack>
            </Flex>
          </Box>

          {isAdmin && (
            <Link to={ROUTES.ADMIN} onClick={onClose}>
              <Button
                leftIcon={<AiOutlineSetting />}
                variant="ghost"
                size="sm"
                width="full"
                justifyContent="flex-start"
                color="gray.600"
                display="flex"
                alignItems="center"
              >
                <Text>Admin</Text>
                <Tag
                  size="sm"
                  colorScheme="yellow"
                  ml={2}
                  lineHeight="shorter"
                  display="flex"
                  alignItems="center"
                >
                  INTERNAL
                </Tag>
              </Button>
            </Link>
          )}

          <Button
            leftIcon={<FaSignOutAlt />}
            onClick={handleSignOut}
            variant="ghost"
            size="sm"
            width="full"
            justifyContent="flex-start"
            color="gray.600"
          >
            Logout
          </Button>
        </VStack>
      </Box>
    </Box>
  );
};

export default SidebarContent;
