import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
  useToast,
  VStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  IconButton,
  Tooltip,
  HStack,
  Select,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Divider,
  Tag,
  Checkbox,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { theme } from "../../constants";
import {
  createOrganization,
  getOrganizations,
  updateOrganization,
  updateOrganizationClientSchema,
} from "../../api/organization";
import {
  OrganizationDto,
  CreateOrganizationDto,
  ClientSchemaFieldDto,
  ClientSchemaFieldType,
} from "../../types/organization";
import { FaPlus, FaCopy, FaEdit, FaTrash } from "react-icons/fa";

const initialForm: CreateOrganizationDto = {
  name: "",
  website: "",
  email: "",
  clientSchema: [],
};

const initialSchemaField: ClientSchemaFieldDto = {
  key: "",
  label: "",
  responseType: ClientSchemaFieldType.TEXT,
  isMultiSelect: false,
};

export const OrganizationTab = () => {
  const {
    isOpen: isCreateOpen,
    onOpen: onCreateOpen,
    onClose: onCreateClose,
  } = useDisclosure();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  const {
    isOpen: isQuickViewOpen,
    onOpen: onQuickViewOpen,
    onClose: onQuickViewClose,
  } = useDisclosure();
  const [form, setForm] = useState<CreateOrganizationDto>(initialForm);
  const [selectedOrg, setSelectedOrg] = useState<OrganizationDto | null>(null);
  const [organizations, setOrganizations] = useState<OrganizationDto[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingOrgs, setIsLoadingOrgs] = useState(false);
  const [newSchemaField, setNewSchemaField] =
    useState<ClientSchemaFieldDto>(initialSchemaField);
  const [dropdownOptions, setDropdownOptions] = useState<string>("");
  const toast = useToast();

  useEffect(() => {
    fetchOrganizations();
  }, []);

  const fetchOrganizations = async () => {
    setIsLoadingOrgs(true);
    try {
      const response = await getOrganizations();
      if ("data" in response && response.data) {
        setOrganizations(response.data);
      } else if ("message" in response) {
        toast({
          title: "Error fetching organizations",
          description: response.message,
          status: "error",
          duration: 3000,
        });
      }
    } catch (error) {
      console.error("Error fetching organizations:", error);
      toast({
        title: "Error fetching organizations",
        status: "error",
        duration: 3000,
      });
    } finally {
      setIsLoadingOrgs(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSelectedOrg((prev) =>
      prev
        ? {
            ...prev,
            [name]: value,
          }
        : null
    );
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      await createOrganization(form);
      toast({
        title: "Organization created successfully",
        status: "success",
        duration: 3000,
      });
      setForm(initialForm);
      fetchOrganizations();
      onCreateClose();
    } catch (error) {
      console.error("Error creating organization:", error);
      toast({
        title: "Error creating organization",
        status: "error",
        duration: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async () => {
    if (!selectedOrg) return;
    setIsLoading(true);
    try {
      const response = await updateOrganization(selectedOrg.id, {
        name: selectedOrg.name,
        website: selectedOrg.website,
        email: selectedOrg.email,
        whatsappApiToken: selectedOrg.whatsappApiToken,
        whatsappBusinessAccountId: selectedOrg.whatsappBusinessAccountId,
        whatsappPhoneNumberId: selectedOrg.whatsappPhoneNumberId,
        productionOrgId: selectedOrg.productionOrgId,
        testOrgId: selectedOrg.testOrgId,
        isTestOrg: selectedOrg.isTestOrg,
      });

      if (response.data) {
        toast({
          title: "Organization updated successfully",
          status: "success",
          duration: 3000,
        });
        fetchOrganizations();
        onEditClose();
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      console.error("Error updating organization:", error);
      toast({
        title: "Error updating organization",
        description:
          error instanceof Error ? error.message : "Unknown error occurred",
        status: "error",
        duration: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSchemaFieldChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setNewSchemaField((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddSchemaField = () => {
    if (!selectedOrg) return;

    // Validate required fields
    if (
      !newSchemaField.key ||
      !newSchemaField.label ||
      !newSchemaField.responseType
    ) {
      toast({
        title: "Missing required fields",
        description: "Please fill in all required fields",
        status: "error",
        duration: 3000,
      });
      return;
    }

    // For dropdown type, validate and parse options
    if (newSchemaField.responseType === ClientSchemaFieldType.DROPDOWN) {
      if (!dropdownOptions) {
        toast({
          title: "Missing dropdown options",
          description: "Please add at least one option for dropdown field",
          status: "error",
          duration: 3000,
        });
        return;
      }
      newSchemaField.dropdownOptions = dropdownOptions
        .split(",")
        .map((opt) => opt.trim());
    }

    // Check for duplicate keys
    if (
      selectedOrg.clientSchema?.some(
        (field) => field.key === newSchemaField.key
      )
    ) {
      toast({
        title: "Duplicate key",
        description: "Field key must be unique",
        status: "error",
        duration: 3000,
      });
      return;
    }

    const updatedSchema = [...(selectedOrg.clientSchema || []), newSchemaField];
    handleUpdateClientSchema(updatedSchema);

    // Reset form
    setNewSchemaField(initialSchemaField);
    setDropdownOptions("");
  };

  const handleRemoveSchemaField = (key: string) => {
    if (!selectedOrg || !selectedOrg.clientSchema) return;

    const updatedSchema = selectedOrg.clientSchema.filter(
      (field) => field.key !== key
    );
    handleUpdateClientSchema(updatedSchema);
  };

  const handleUpdateClientSchema = async (schema: ClientSchemaFieldDto[]) => {
    if (!selectedOrg) return;
    setIsLoading(true);
    try {
      const response = await updateOrganizationClientSchema(
        selectedOrg.id,
        schema
      );
      if (response.data) {
        setSelectedOrg(response.data);
        toast({
          title: "Client schema updated successfully",
          status: "success",
          duration: 3000,
        });
        fetchOrganizations();
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      console.error("Error updating client schema:", error);
      toast({
        title: "Error updating client schema",
        description:
          error instanceof Error ? error.message : "Unknown error occurred",
        status: "error",
        duration: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopyId = (id: string) => {
    navigator.clipboard.writeText(id);
    toast({
      title: "Organization ID copied",
      status: "success",
      duration: 2000,
    });
  };

  const handleRowClick = (org: OrganizationDto) => {
    setSelectedOrg(org);
    onQuickViewOpen();
  };

  return (
    <VStack spacing={6} align="stretch">
      <Box>
        <HStack justify="space-between" mb={4}>
          <Text fontSize="lg" fontWeight="semibold" color={`${theme}.800`}>
            Organizations
          </Text>
          <Button leftIcon={<FaPlus />} onClick={onCreateOpen}>
            Create Organization
          </Button>
        </HStack>

        {isLoadingOrgs ? (
          <Spinner />
        ) : organizations.length === 0 ? (
          <Text color="gray.500">No organizations found</Text>
        ) : (
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Email</Th>
                <Th>Mode</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {organizations.map((org) => (
                <Tr
                  key={org.id}
                  cursor="pointer"
                  _hover={{ bg: "gray.50" }}
                  onClick={() => handleRowClick(org)}
                >
                  <Td>{org.name}</Td>
                  <Td>{org.email}</Td>
                  <Td>
                    <Tag
                      size="sm"
                      colorScheme={org.isTestOrg ? "yellow" : "blue"}
                      variant="subtle"
                    >
                      {org.isTestOrg ? "TEST" : "LIVE"}
                    </Tag>
                  </Td>
                  <Td onClick={(e) => e.stopPropagation()}>
                    <IconButton
                      aria-label="Edit organization"
                      icon={<FaEdit />}
                      size="sm"
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedOrg(org);
                        onEditOpen();
                      }}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </Box>

      {/* Quick View Modal */}
      <Modal isOpen={isQuickViewOpen} onClose={onQuickViewClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Organization Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedOrg && (
              <VStack spacing={4} align="stretch">
                <HStack justify="space-between">
                  <Text fontWeight="bold">{selectedOrg.name}</Text>
                  <Tag
                    size="sm"
                    colorScheme={selectedOrg.isTestOrg ? "yellow" : "blue"}
                    variant="subtle"
                  >
                    {selectedOrg.isTestOrg ? "Test Mode" : "Live Mode"}
                  </Tag>
                </HStack>

                <Divider />

                <Box>
                  <Text fontWeight="medium" mb={1}>
                    Basic Information
                  </Text>
                  <VStack align="stretch" pl={4} spacing={2}>
                    <HStack>
                      <Text color="gray.500" minW="100px">
                        ID:
                      </Text>
                      <Text>{selectedOrg.id}</Text>
                      <IconButton
                        aria-label="Copy ID"
                        icon={<FaCopy />}
                        size="xs"
                        variant="ghost"
                        onClick={() => handleCopyId(selectedOrg.id)}
                      />
                    </HStack>
                    <HStack>
                      <Text color="gray.500" minW="100px">
                        Email:
                      </Text>
                      <Text>{selectedOrg.email}</Text>
                    </HStack>
                    <HStack>
                      <Text color="gray.500" minW="100px">
                        Website:
                      </Text>
                      <Text>{selectedOrg.website}</Text>
                    </HStack>
                  </VStack>
                </Box>

                <Box>
                  <Text fontWeight="medium" mb={1}>
                    Environment Details
                  </Text>
                  <VStack align="stretch" pl={4} spacing={2}>
                    {selectedOrg.isTestOrg ? (
                      <HStack spacing={4} align="center">
                        <Text color="gray.500" w="100px">
                          Production:
                        </Text>
                        <Text>
                          {selectedOrg.productionOrgId
                            ? organizations.find(
                                (org) => org.id === selectedOrg.productionOrgId
                              )?.name || "Unknown"
                            : "None"}
                        </Text>
                      </HStack>
                    ) : (
                      <HStack spacing={4} align="center">
                        <Text color="gray.500" w="100px">
                          Test:
                        </Text>
                        <Text>
                          {selectedOrg.testOrgId
                            ? organizations.find(
                                (org) => org.id === selectedOrg.testOrgId
                              )?.name || "Unknown"
                            : "None"}
                        </Text>
                      </HStack>
                    )}
                  </VStack>
                </Box>

                {(selectedOrg.whatsappApiToken ||
                  selectedOrg.whatsappBusinessAccountId ||
                  selectedOrg.whatsappPhoneNumberId) && (
                  <Box>
                    <Text fontWeight="medium" mb={1}>
                      WhatsApp Configuration
                    </Text>
                    <VStack align="stretch" pl={4} spacing={2}>
                      {selectedOrg.whatsappApiToken && (
                        <HStack spacing={4} align="center">
                          <Text color="gray.500" w="100px">
                            API Token:
                          </Text>
                          <Text flex="1" maxW="350px" isTruncated>
                            {selectedOrg.whatsappApiToken}
                          </Text>
                          <IconButton
                            aria-label="Copy API Token"
                            icon={<FaCopy />}
                            size="xs"
                            variant="ghost"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                selectedOrg.whatsappApiToken || ""
                              );
                              toast({
                                title: "API Token copied",
                                status: "success",
                                duration: 2000,
                              });
                            }}
                          />
                        </HStack>
                      )}
                      {selectedOrg.whatsappBusinessAccountId && (
                        <HStack spacing={4} align="center">
                          <Text color="gray.500" w="100px">
                            Business ID:
                          </Text>
                          <Text flex="1" maxW="350px" isTruncated>
                            {selectedOrg.whatsappBusinessAccountId}
                          </Text>
                          <IconButton
                            aria-label="Copy Business Account ID"
                            icon={<FaCopy />}
                            size="xs"
                            variant="ghost"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                selectedOrg.whatsappBusinessAccountId || ""
                              );
                              toast({
                                title: "Business Account ID copied",
                                status: "success",
                                duration: 2000,
                              });
                            }}
                          />
                        </HStack>
                      )}
                      {selectedOrg.whatsappPhoneNumberId && (
                        <HStack spacing={4} align="center">
                          <Text color="gray.500" w="100px">
                            Phone ID:
                          </Text>
                          <Text flex="1" maxW="350px" isTruncated>
                            {selectedOrg.whatsappPhoneNumberId}
                          </Text>
                          <IconButton
                            aria-label="Copy Phone Number ID"
                            icon={<FaCopy />}
                            size="xs"
                            variant="ghost"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                selectedOrg.whatsappPhoneNumberId || ""
                              );
                              toast({
                                title: "Phone Number ID copied",
                                status: "success",
                                duration: 2000,
                              });
                            }}
                          />
                        </HStack>
                      )}
                    </VStack>
                  </Box>
                )}
              </VStack>
            )}
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onQuickViewClose}>
              Close
            </Button>
            <Button
              colorScheme="blue"
              onClick={() => {
                onQuickViewClose();
                onEditOpen();
              }}
            >
              Edit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Create Organization Modal */}
      <Modal isOpen={isCreateOpen} onClose={onCreateClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create New Organization</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <FormControl isRequired>
                <FormLabel>Organization Name</FormLabel>
                <Input
                  name="name"
                  value={form.name}
                  onChange={handleInputChange}
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Website</FormLabel>
                <Input
                  name="website"
                  value={form.website}
                  onChange={handleInputChange}
                  placeholder="e.g., https://pacific-health.com"
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Email</FormLabel>
                <Input
                  name="email"
                  type="email"
                  value={form.email}
                  onChange={handleInputChange}
                  placeholder="e.g., admin@pacific-health.com"
                />
              </FormControl>

              <FormControl>
                <FormLabel>Environment Type</FormLabel>
                <Select
                  name="isTestOrg"
                  value={form.isTestOrg ? "true" : "false"}
                  onChange={(e) =>
                    setForm((prev) => ({
                      ...prev,
                      isTestOrg: e.target.value === "true",
                    }))
                  }
                >
                  <option value="false">Production</option>
                  <option value="true">Test</option>
                </Select>
              </FormControl>

              {form.isTestOrg && (
                <FormControl>
                  <FormLabel>Link to Production Organization</FormLabel>
                  <Select
                    name="productionOrgId"
                    value={form.productionOrgId || ""}
                    onChange={(e) =>
                      setForm((prev) => ({
                        ...prev,
                        productionOrgId: e.target.value || undefined,
                      }))
                    }
                  >
                    <option value="">Select Production Organization</option>
                    {organizations
                      .filter((org) => !org.isTestOrg && !org.testOrgId)
                      .map((org) => (
                        <option key={org.id} value={org.id}>
                          {org.name}
                        </option>
                      ))}
                  </Select>
                </FormControl>
              )}

              {!form.isTestOrg && (
                <FormControl>
                  <FormLabel>Link to Test Organization</FormLabel>
                  <Select
                    name="testOrgId"
                    value={form.testOrgId || ""}
                    onChange={(e) =>
                      setForm((prev) => ({
                        ...prev,
                        testOrgId: e.target.value || undefined,
                      }))
                    }
                  >
                    <option value="">Select Test Organization</option>
                    {organizations
                      .filter((org) => org.isTestOrg && !org.productionOrgId)
                      .map((org) => (
                        <option key={org.id} value={org.id}>
                          {org.name}
                        </option>
                      ))}
                  </Select>
                </FormControl>
              )}
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onCreateClose}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleSubmit}
              isLoading={isLoading}
            >
              Create
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Edit Organization Modal */}
      <Modal isOpen={isEditOpen} onClose={onEditClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Organization</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Tabs>
              <TabList>
                <Tab>Basic Info</Tab>
                <Tab>Client Schema</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <VStack spacing={4}>
                    <FormControl isRequired>
                      <FormLabel>Organization Name</FormLabel>
                      <Input
                        name="name"
                        value={selectedOrg?.name || ""}
                        onChange={handleEditInputChange}
                      />
                    </FormControl>

                    <FormControl isRequired>
                      <FormLabel>Website</FormLabel>
                      <Input
                        name="website"
                        value={selectedOrg?.website || ""}
                        onChange={handleEditInputChange}
                        placeholder="e.g., https://pacific-health.com"
                      />
                    </FormControl>

                    <FormControl isRequired>
                      <FormLabel>Email</FormLabel>
                      <Input
                        name="email"
                        type="email"
                        value={selectedOrg?.email || ""}
                        onChange={handleEditInputChange}
                        placeholder="e.g., admin@pacific-health.com"
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel>Environment Type</FormLabel>
                      <Select
                        name="isTestOrg"
                        value={selectedOrg?.isTestOrg ? "true" : "false"}
                        onChange={(e) =>
                          setSelectedOrg((prev) =>
                            prev
                              ? {
                                  ...prev,
                                  isTestOrg: e.target.value === "true",
                                }
                              : null
                          )
                        }
                        isDisabled={true}
                      >
                        <option value="false">Production</option>
                        <option value="true">Test</option>
                      </Select>
                      <Text fontSize="sm" color="gray.500" mt={1}>
                        Environment type cannot be changed after creation
                      </Text>
                    </FormControl>

                    {selectedOrg?.isTestOrg && (
                      <FormControl>
                        <FormLabel>Link to Live Mode Organization</FormLabel>
                        <Select
                          name="productionOrgId"
                          value={selectedOrg?.productionOrgId || ""}
                          onChange={(e) =>
                            setSelectedOrg((prev) =>
                              prev
                                ? {
                                    ...prev,
                                    productionOrgId:
                                      e.target.value || undefined,
                                  }
                                : null
                            )
                          }
                        >
                          <option value="">
                            Select Live Mode Organization
                          </option>
                          {selectedOrg &&
                            organizations
                              .filter(
                                (org) =>
                                  !org.isTestOrg &&
                                  (!org.testOrgId ||
                                    org.testOrgId === selectedOrg.id)
                              )
                              .map((org) => (
                                <option key={org.id} value={org.id}>
                                  {org.name}
                                </option>
                              ))}
                        </Select>
                      </FormControl>
                    )}

                    {!selectedOrg?.isTestOrg && selectedOrg && (
                      <FormControl>
                        <FormLabel>Link to Test Mode Organization</FormLabel>
                        <Select
                          name="testOrgId"
                          value={selectedOrg?.testOrgId || ""}
                          onChange={(e) =>
                            setSelectedOrg((prev) =>
                              prev
                                ? {
                                    ...prev,
                                    testOrgId: e.target.value || undefined,
                                  }
                                : null
                            )
                          }
                        >
                          <option value="">
                            Select Test Mode Organization
                          </option>
                          {organizations
                            .filter(
                              (org) =>
                                org.isTestOrg &&
                                (!org.productionOrgId ||
                                  org.productionOrgId === selectedOrg.id)
                            )
                            .map((org) => (
                              <option key={org.id} value={org.id}>
                                {org.name}
                              </option>
                            ))}
                        </Select>
                      </FormControl>
                    )}

                    <FormControl>
                      <FormLabel>WhatsApp API Token</FormLabel>
                      <Input
                        name="whatsappApiToken"
                        value={selectedOrg?.whatsappApiToken || ""}
                        onChange={handleEditInputChange}
                        placeholder="Enter WhatsApp API token"
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel>WhatsApp Business Account ID</FormLabel>
                      <Input
                        name="whatsappBusinessAccountId"
                        value={selectedOrg?.whatsappBusinessAccountId || ""}
                        onChange={handleEditInputChange}
                        placeholder="Enter WhatsApp Business Account ID"
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel>WhatsApp Phone Number ID</FormLabel>
                      <Input
                        name="whatsappPhoneNumberId"
                        value={selectedOrg?.whatsappPhoneNumberId || ""}
                        onChange={handleEditInputChange}
                        placeholder="Enter WhatsApp Phone Number ID"
                      />
                    </FormControl>
                  </VStack>
                </TabPanel>

                <TabPanel>
                  <VStack spacing={4} align="stretch">
                    <Box>
                      <Text fontSize="md" fontWeight="semibold" mb={2}>
                        Add New Field
                      </Text>
                      <VStack spacing={4}>
                        <FormControl isRequired>
                          <FormLabel>Field Key</FormLabel>
                          <Input
                            name="key"
                            value={newSchemaField.key}
                            onChange={handleSchemaFieldChange}
                            placeholder="e.g., preferredLanguage"
                          />
                        </FormControl>

                        <FormControl isRequired>
                          <FormLabel>Field Label</FormLabel>
                          <Input
                            name="label"
                            value={newSchemaField.label}
                            onChange={handleSchemaFieldChange}
                            placeholder="e.g., Preferred Language"
                          />
                        </FormControl>

                        <FormControl isRequired>
                          <FormLabel>Response Type</FormLabel>
                          <Select
                            name="responseType"
                            value={newSchemaField.responseType}
                            onChange={handleSchemaFieldChange}
                          >
                            <option value={ClientSchemaFieldType.TEXT}>
                              Text
                            </option>
                            <option value={ClientSchemaFieldType.NUMBER}>
                              Number
                            </option>
                            <option value={ClientSchemaFieldType.CHECKBOX}>
                              Checkbox
                            </option>
                            <option value={ClientSchemaFieldType.DROPDOWN}>
                              Dropdown
                            </option>
                          </Select>
                        </FormControl>

                        {newSchemaField.responseType ===
                          ClientSchemaFieldType.DROPDOWN && (
                          <>
                            <FormControl isRequired>
                              <FormLabel>
                                Dropdown Options (comma-separated)
                              </FormLabel>
                              <Input
                                value={dropdownOptions}
                                onChange={(e) =>
                                  setDropdownOptions(e.target.value)
                                }
                                placeholder="e.g., English, Samoan, Tongan"
                              />
                            </FormControl>
                            <FormControl>
                              <Checkbox
                                onChange={(e) => {
                                  setNewSchemaField((prev) => ({
                                    ...prev,
                                    isMultiSelect: e.target.checked,
                                  }));
                                }}
                                isChecked={newSchemaField.isMultiSelect}
                              >
                                Allow multiple selections
                              </Checkbox>
                            </FormControl>
                          </>
                        )}

                        <Button
                          onClick={handleAddSchemaField}
                          leftIcon={<FaPlus />}
                        >
                          Add Field
                        </Button>
                      </VStack>
                    </Box>

                    <Divider my={4} />

                    <Box>
                      <Text fontSize="md" fontWeight="semibold" mb={2}>
                        Existing Fields
                      </Text>
                      <Table variant="simple" size="sm">
                        <Thead>
                          <Tr>
                            <Th>Key</Th>
                            <Th>Label</Th>
                            <Th>Type</Th>
                            <Th>Actions</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {selectedOrg?.clientSchema?.map((field) => (
                            <Tr key={field.key}>
                              <Td>{field.key}</Td>
                              <Td>{field.label}</Td>
                              <Td>{field.responseType}</Td>
                              <Td>
                                <IconButton
                                  aria-label="Remove field"
                                  icon={<FaTrash />}
                                  size="sm"
                                  colorScheme="red"
                                  onClick={() =>
                                    handleRemoveSchemaField(field.key)
                                  }
                                />
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </Box>
                  </VStack>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onEditClose}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleUpdate}
              isLoading={isLoading}
            >
              Update
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};
