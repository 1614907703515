// 1. import `extendTheme` function
import {
  defineStyleConfig,
  extendTheme,
  withDefaultColorScheme,
  type ThemeConfig,
} from "@chakra-ui/react";
import { backgroundColor, theme as constantTheme } from "../constants";
import '@fontsource/dm-mono';
import '@fontsource-variable/work-sans';
import '@fontsource-variable/montserrat';

// 2. Add your color mode config
const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const styles = {
  global: (props: any) => ({
    body: {
      background: backgroundColor,
    },
    "@keyframes shimmer": {
      "0%, 100%": {
        transform: "translateX(-30%)",
      },
      "50%": {
        transform: "translateX(30%)",
      },
    },
  }),
};

const colors = {
  red: {
    50: "#fff5f5",
    100: "#fed7d7",
    200: "#feb2b2",
    300: "#fc8181",
    400: "#f56565",
    500: "#e53e3e",
    600: "#c53030",
    700: "#9b2c2c",
    800: "#822727",
    900: "#63171b",
  },
  green: {
    50: "#f0fff4",
    100: "#c6f6d5",
    200: "#9ae6b4",
    300: "#68d391",
    400: "#48bb78",
    500: "#38a169",
    600: "#2f855a",
    700: "#276749",
    800: "#22543d",
    900: "#1c4532",
  },
  blue: {
    50: "#ebf8ff",
    100: "#bee3f8",
    200: "#90cdf4",
    300: "#63b3ed",
    400: "#4299e1",
    500: "#3182ce",
    600: "#2b6cb0",
    700: "#2c5282",
    800: "#2a4365",
    900: "#1A365D",
  },
  yellow: {
    50: "#fffff0",
    100: "#fefcbf",
    200: "#faf089",
    300: "#f6e05e",
    400: "#ecc94b",
    500: "#d69e2e",
    600: "#b7791f",
    700: "#975a16",
    800: "#744210",
    900: "#5F370E",
  },
  purple: {
    50: "#faf5ff",
    100: "#e9d8fd",
    200: "#d6bcfa",
    300: "#b794f4",
    400: "#9f7aea",
    500: "#805ad5",
    600: "#6b46c1",
    700: "#553c9a",
    800: "#44337a",
    900: "#322659",
  },
  orange: {
    50: "#fffaf0",
    100: "#feebc8",
    200: "#fbd38d",
    300: "#f6ad55",
    400: "#ed8936",
    500: "#dd6b20",
    600: "#c05621",
    700: "#9c4221",
    800: "#7b341e",
    900: "#652b19",
  },
  teal: {
    50: "#e6fffa",
    100: "#b2f5ea",
    200: "#81e6d9",
    300: "#4fd1c5",
    400: "#38b2ac",
    500: "#319795",
    600: "#2c7a7b",
    700: "#285e61",
    800: "#234e52",
    900: "#1D4044",
  },
  cyan: {
    50: "#edfdfd",
    100: "#c4f1f9",
    200: "#9decf9",
    300: "#76e4f7",
    400: "#0bc5ea",
    500: "#00b5d8",
    600: "#00a3c4",
    700: "#0987a0",
    800: "#086f83",
    900: "#065666",
  },
  pink: {
    50: "#fff5f7",
    100: "#fed7e2",
    200: "#fbb6ce",
    300: "#f687b3",
    400: "#ed64a6",
    500: "#d53f8c",
    600: "#b83280",
    700: "#97266d",
    800: "#702459",
    900: "#521B41",
  },
  gray: {
    50: "#f7fafc",
    100: "#edf2f7",
    200: "#e2e8f0",
    300: "#cbd5e0",
    400: "#a0aec0",
    500: "#718096",
    600: "#4a5568",
    700: "#2d3748",
    800: "#1a202c",
    900: "#171923",
  },
  white: "#FFFFFF",
  black: "#000000",
};

const spacing = {
  px: "1px",
  0: "0",
  0.5: "0.125rem",
  1: "0.25rem",
  1.5: "0.375rem",
  2: "0.5rem",
  2.5: "0.625rem",
  3: "0.75rem",
  3.5: "0.875rem",
  4: "1rem",
  5: "1.25rem",
  6: "1.5rem",
  7: "1.75rem",
  8: "2rem",
  9: "2.25rem",
  10: "2.5rem",
  12: "3rem",
  14: "3.5rem",
  16: "4rem",
  20: "5rem",
  24: "6rem",
  28: "7rem",
  32: "8rem",
  36: "9rem",
  40: "10rem",
  44: "11rem",
  48: "12rem",
  52: "13rem",
  56: "14rem",
  60: "15rem",
  64: "16rem",
  72: "18rem",
  80: "20rem",
  96: "24rem",
};

const fontSettings = {
  fonts: {
    body: `'Work Sans Variable', sans-serif`,
    heading: `'Montserrat Variable', sans-serif`,
    mono: `'DM Mono', monospace`,
  },
  fontSizes: {
    xs: "12px",
    s: "14px",
    md: "16px",
    lg: "18px",
    xl: "20px",
    "2xl": "24px",
    "3xl": "30px",
    "4xl": "36px",
    "5xl": "48px",
    "6xl": "60px",
    "7xl": "72px",
    "8xl": "96px",
    "9xl": "128px",
  },
  fontWeights: {
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900
  },
}

const borderRadius = {
  none: "0",
  sm: "0.125rem",
  base: "0.25rem",
  md: "0.375rem",
  lg: "0.5rem",
  xl: "0.75rem",
  "2xl": "1rem",
  "3xl": "1.5rem",
  full: "9999px",
};

const zIndices = {
  hide: -1,
  auto: "auto",
  base: 0,
  docked: 10,
  dropdown: 1000,
  sticky: 1100,
  banner: 1200,
  overlay: 1300,
  modal: 1400,
  popover: 1500,
  skipLink: 1600,
  toast: 1700,
  tooltip: 1800,
};

const breakpoints = {
  base: '0em',
  sm: '30em',
  md: '48em',
  lg: '62em',
  xl: '80em',
  '2xl': '96em',
}

const sizes = {
  max: 'max-content',
  min: 'min-content',
  full: '100%',
  '10xs': '1rem',
  '7xs': '3.5rem',
  '5xs': '6rem',
  '3xs': '14rem',
  '2xs': '16rem',
  '1.5xs': '18rem',
  xs: '20rem',
  sm: '24rem',
  md: '28rem',
  lg: '32rem',
  xl: '36rem',
  '2xl': '42rem',
  '3xl': '48rem',
  '4xl': '56rem',
  '5xl': '64rem',
  '6xl': '72rem',
  '7xl': '80rem',
  '8xl': '90rem',
  container: {
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
  },
};

// 3. Extend the theme
const theme = extendTheme(
  withDefaultColorScheme({
    colorScheme: constantTheme,
  }),
  {
    config,
    styles,
    colors,
    space: spacing,
    ...fontSettings,
    borderRadius,
    zIndices,
    sizes,
    breakpoints,
    components: {
      Button: defineStyleConfig({
        variants: {
          outline: {
            border: '2px solid',
            borderColor: `${constantTheme}.700`,
            color: `${constantTheme}.700`,
            _hover: {
              bg: `${constantTheme}.900`,
              color: `${constantTheme}.900`,
            },
          },
          solid: {
            bg: `${constantTheme}.700`,
            _hover: {
              bg: `${constantTheme}.900`,
            },
            color: 'white',
          },
        }
      }),
      // ... other component styles
    },
  }
);

export default theme;
