import {
	useTheme,
	Box,
	Circle,
	Input,
	HStack,
} from '@chakra-ui/react';

export type PasscodeInputProps = {
	passcode: string;
	setPasscode: (value: string) => void;
	onSubmit?: () => void;
};

const PasscodeInput = (props: PasscodeInputProps) => {
	const theme = useTheme()
	
	const handleChange = (e: any) => {
		const value = e.target.value;
		// Allow only numbers and limit to 6 characters
		if (/^\d*$/.test(value) && value.length <= 6) {
			props.setPasscode(value);
		}
	};

	const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter' && props.passcode.length === 6 && props.onSubmit) {
			props.onSubmit();
		}
	};
	
	return (
		<Box
		position="relative"
		w="100%"
		maxW="300px"
		mx="auto"
		px={2}
		>
		{/* Hidden Input to capture the keyboard input */}
		<Input
		type="tel"
		position="absolute"
		top="0"
		left="0"
		opacity="0"
		value={props.passcode}
		onChange={handleChange}
		onKeyPress={handleKeyPress}
		autoFocus
		aria-hidden="true"
		/>
		
		{/* Display the circles */}
		<HStack spacing={2} justify="center">
		{[...Array(6)].map((_, index) => (
			<Box
			key={index}
			borderWidth={3}
			padding={3}
			borderColor={
				props.passcode.length > index ?
				theme.colors.blue['700'] :
				theme.colors.gray['100']
			}
			>
			<Circle
			size="20px"
			bg={
				props.passcode.length > index ?
				theme.colors.blue['700'] :
				theme.colors.gray['100']
			}
			/>
			</Box>
		))}
		</HStack>
		</Box>
	);
};

export {
	PasscodeInput,
}
