import { Navigate } from "react-router-dom";
import { ROUTES } from "../../../constants";
import { useStaffStore } from "../../../store/staffStore";
import { StaffAuthLevel } from "../../../types/staff";

interface AdminProtectedRouteProps {
  children: React.ReactNode;
}

export const AdminProtectedRoute = ({ children }: AdminProtectedRouteProps) => {
  const { currentStaff } = useStaffStore();

  if (!currentStaff || currentStaff.authLevel !== StaffAuthLevel.ADMIN) {
    // User is not an admin, redirect to home
    return <Navigate to={ROUTES.CLIENTS} />;
  }

  return <>{children}</>;
}; 