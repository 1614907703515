import { FC } from 'react';
import { ChartData, ChartDataset } from "chart.js";
import LineChartComponent from "../../components/Patients/LineChartComponent";
import {
    getAllLabels,
    TimePeriod,
    TimePeriodToTimeQuanta,
    TimeQunataToLabelFunc
} from '../../constants/time';
import { getFirstDate } from '../../constants/time';
import { Center, Text } from '@chakra-ui/react';

export type DataPoint = {
    propertyId: string
    value: number
    timestamp: number
}

export type PropertyToDisplay = {
    id: string
    name: string
}
export type GraphProps = {
    /*
        data point values grouped by recorded time
        each element in metricGroupedByTime is an array of PatientMetricDto recorded at the same time
    */
    dataPointsGroupedByTime: DataPoint[][];
    properties: PropertyToDisplay[]; // list of properties from dataPointsGroupedByTime to display on the graph
    selectedTimePeriod: TimePeriod
    endTime: number
}

const Graph: FC<GraphProps> = (props: GraphProps) => {
    const getGraphData = (): ChartData => {
        if (props.dataPointsGroupedByTime.length === 0) return { labels: [], datasets: [] };

        let filtered = filterByPropertyIds(props.dataPointsGroupedByTime, props.properties);
        filtered = filterByTimePeriod(filtered, props.selectedTimePeriod, props.endTime);

        if (filtered.length === 0) return { labels: [], datasets: [] }
        return {
            labels: getAllLabels(
                props.selectedTimePeriod,
                props.endTime
            ),
            datasets: generateDataset(
                filtered,
                props.properties,
                props.selectedTimePeriod,
                props.endTime
            ),
        };
    };
    const data = getGraphData();
    if (data.datasets.length === 0) {
        return (
            <Center>
                <Text>No records in the chosen time period</Text>
            </Center>
        )
    }
    return (
        <LineChartComponent data={data} />
    )
}

function filterByPropertyIds(metrics: DataPoint[][], properties: PropertyToDisplay[]): DataPoint[][] {
    const filtered: DataPoint[][] = [];
    metrics.forEach((m) => {
        const filteredMetrics = m.filter((d) =>
            properties.map((p) => (p.id)).includes(d.propertyId)
        );
        if (filteredMetrics.length > 0) {
            filtered.push(filteredMetrics);
        }
    });
    return filtered;
}

function generateDataset(
    metricsByTime: DataPoint[][],
    properties: PropertyToDisplay[],
    selectedTimePeriod: TimePeriod,
    endTime: number
): ChartDataset[] {
    const allLabels = getAllLabels(selectedTimePeriod, endTime);
    const timeQuanta = TimePeriodToTimeQuanta[selectedTimePeriod];
    const timestampToLabelFunc = TimeQunataToLabelFunc[timeQuanta];

    return properties.map((property, i) => {
        const propertyMetrics = metricsByTime.map((m) => {
            return m.find((d) => d.propertyId === property.id);
        })
        let labelToValueMap: { [label: string]: { value: number, timestamp: number } } = {};
        propertyMetrics.forEach((m) => {
            if (!m) {
                return;
            }
            const label = timestampToLabelFunc(m.timestamp);
            const exitsingValue = labelToValueMap[label];
            if (!exitsingValue) {
                labelToValueMap[label] = {
                    value: m.value,
                    timestamp: m.timestamp
                };
                return;
            }
            if (m.timestamp > exitsingValue.timestamp) { // take latest value for time quanta
                labelToValueMap[label] = {
                    value: m.value,
                    timestamp: m.timestamp
                };
            }
        })
        const values = allLabels.map((label) => {
            return labelToValueMap[label]?.value ?? null;
        })
        return {
            label: property.name,
            data: values,
            fill: false,
            borderColor: `rgb(${i * 200}, 99, 132)`,
            tension: 0.1,
            spanGaps: true
        };
    })
}

function filterByTimePeriod(metrics: DataPoint[][], timePeriod: TimePeriod, endTime: number): DataPoint[][] {
    const startTime = getFirstDate(timePeriod, endTime).getTime();
    return metrics.filter((m) => {
        const metricTime = m[0].timestamp;
        return metricTime >= startTime && metricTime <= endTime;
    });
}

export { Graph }
