import {
  VStack,
  Text,
  Button,
  HStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Spinner,
  Box,
  FormControl,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { theme } from "../../constants";
import { getOrganizations } from "../../api/organization";
import { getAllPatientsAdmin } from "../../api/patients";
import { resetClientCredentials } from "../../api/staffAuth";
import { OrganizationDto } from "../../types/organization";
import { PatientDto } from "../../types/patient";
import { FaSearch, FaKey } from "react-icons/fa";
import { CopyIcon } from "@chakra-ui/icons";

type PatientWithOrg = PatientDto & {
  organizationName: string;
};

export const PatientsTab = () => {
  const [patients, setPatients] = useState<PatientWithOrg[]>([]);
  const [organizations, setOrganizations] = useState<OrganizationDto[]>([]);
  const [selectedOrgFilter, setSelectedOrgFilter] = useState<string>("all");
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState<PatientWithOrg | null>(
    null
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      // Fetch organizations
      const orgsResponse = await getOrganizations();
      if ("data" in orgsResponse && orgsResponse.data) {
        setOrganizations(orgsResponse.data);
      }

      // Fetch patients using admin endpoint with a large size to get all patients
      const patientsResponse = await getAllPatientsAdmin({
        size: 1000, // Set a large number to get all patients
        page: 1,
      });
      if (patientsResponse.data) {
        const patientsWithOrgs = patientsResponse.data.data.map((patient) => ({
          ...patient,
          organizationName:
            orgsResponse.data?.find((org) => org.id === patient.tenantId)
              ?.name || "Unknown Organization",
        }));
        setPatients(patientsWithOrgs);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast({
        title: "Error fetching data",
        description:
          error instanceof Error ? error.message : "Failed to fetch data",
        status: "error",
        duration: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleResetCredentials = async () => {
    if (!selectedPatient) return;

    try {
      const response = await resetClientCredentials(selectedPatient.id);

      if (response.error) {
        throw new Error(response.error);
      }

      toast({
        title: "Credentials reset successfully",
        description: "A new registration link will be sent to the patient",
        status: "success",
        duration: 3000,
      });
      onClose();
    } catch (error) {
      console.error("Error resetting credentials:", error);
      toast({
        title: "Error resetting credentials",
        description:
          error instanceof Error
            ? error.message
            : "Failed to reset client credentials",
        status: "error",
        duration: 3000,
      });
    }
  };

  const filteredPatients = patients
    .filter((patient) => {
      // Filter by organization
      if (
        selectedOrgFilter !== "all" &&
        patient.tenantId !== selectedOrgFilter
      ) {
        return false;
      }
      // Filter by search query
      if (
        searchQuery &&
        !patient.name.toLowerCase().includes(searchQuery.toLowerCase())
      ) {
        return false;
      }
      return true;
    })
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <VStack spacing={6} align="stretch">
      <Box>
        <Text fontSize="lg" fontWeight="semibold" color={`${theme}.800`} mb={4}>
          Patients
        </Text>

        <HStack spacing={4} mb={4}>
          <FormControl>
            <FormLabel>Organization</FormLabel>
            <Select
              value={selectedOrgFilter}
              onChange={(e) => setSelectedOrgFilter(e.target.value)}
            >
              <option value="all">All Organizations</option>
              {organizations.map((org) => (
                <option key={org.id} value={org.id}>
                  {org.name}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel>Search</FormLabel>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <FaSearch color="gray.300" />
              </InputLeftElement>
              <Input
                placeholder="Search by name..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </InputGroup>
          </FormControl>
        </HStack>

        {isLoading ? (
          <Spinner />
        ) : patients.length === 0 ? (
          <Text color="gray.500">No patients found</Text>
        ) : (
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>Name</Th>
                <Th>Organization</Th>
                <Th>WhatsApp</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredPatients.map((patient) => (
                <Tr key={patient.id}>
                  <Td>
                    <HStack>
                      <Text>{patient.id}</Text>
                      <IconButton
                        aria-label="Copy ID"
                        icon={<CopyIcon />}
                        size="sm"
                        variant="ghost"
                        onClick={(e) => {
                          e.stopPropagation();
                          navigator.clipboard.writeText(patient.id);
                          toast({
                            title: "ID Copied",
                            status: "success",
                            duration: 2000,
                          });
                        }}
                      />
                    </HStack>
                  </Td>
                  <Td>{patient.name}</Td>
                  <Td>{patient.organizationName}</Td>
                  <Td>
                    {patient.whatsappPhoneNum ? (
                      <HStack spacing={2}>
                        <Text
                          cursor="pointer"
                          onClick={() =>
                            window.open(
                              `https://wa.me/${patient.whatsappPhoneNum}`,
                              "_blank"
                            )
                          }
                        >
                          {patient.whatsappPhoneNum}
                        </Text>
                      </HStack>
                    ) : (
                      <Text color="gray.500">Not provided</Text>
                    )}
                  </Td>
                  <Td>
                    <IconButton
                      aria-label="Reset credentials"
                      icon={<FaKey />}
                      size="sm"
                      colorScheme="blue"
                      onClick={() => {
                        setSelectedPatient(patient);
                        onOpen();
                      }}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </Box>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Reset Patient Credentials</ModalHeader>
          <ModalBody>
            <Text>
              Are you sure you want to reset credentials for{" "}
              <strong>{selectedPatient?.name}</strong>?
            </Text>
            <Text mt={2} color="gray.600" fontSize="sm">
              This will invalidate their current login and send them a new
              registration link.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={handleResetCredentials}>
              Reset Credentials
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};
