import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Stack,
  VStack,
  useToast,
  Text,
  HStack,
  Icon,
  InputGroup,
  InputLeftElement,
  Input,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { useEffect, useState, useCallback } from "react";
import {
  bulkCreatePatients,
  createPatient,
  deletePatient,
  getPatientList,
  updatePatient,
} from "../../api/patients";
import Header from "../../components/Head"; // Update path to Header component
import PatientModal from "../../components/Patients/PatientModal";
import { PatientTable } from "../../components/Patients/PatientTable";
import TablePagination from "../../components/TablePagination";
import { PatientDto, PatientQueryParams } from "../../types/patient";
import { downloadClientTemplate } from "../../utils";
import BulkUploadModal from "../../components/Patients/BulkPatientUploadModal";
import { SearchByName } from "../../components/SearchByName";
import { getAllStaffList } from "../../api/staff";
import { StaffDto } from "../../types/staff";
import { StaffSelect } from "../../components/StaffSelect";
import { useStaffStore } from "../../store/staffStore";
import { FaSearch } from "react-icons/fa";
import { debounce } from "lodash";

export default function ViewPatientsPage() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allPatients, setAllPatients] = useState<PatientDto[]>([]);
  const [nameFilter, setNameFilter] = useState("");
  const [staffFilter, setStaffFilter] = useState("");
  const [selectedPatient, setSelectedPatient] = useState<PatientDto | null>(
    null
  );
  const [staffList, setStaffList] = useState<StaffDto[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const currentStaff = useStaffStore((state) => state.currentStaff);
  const toast = useToast();

  const fetchAllPatients = async () => {
    try {
      setLoading(true);
      const resp = await getPatientList({
        size: 1000,
        page: 1,
      });
      if (resp.data) {
        setAllPatients(resp.data.data);
      }
    } catch (error: any) {
      if (error.response?.status === 401) return;
      toast({
        title: "Failed to fetch patient info",
        description: error?.response?.data?.message || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const getStaffDetails = async () => {
    const resp = await getAllStaffList(currentStaff?.organizationId || "");
    if (resp.message) {
      toast({
        title: "Failed to fetch staff info",
        description: resp.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    setStaffList(resp.data || []);
  };

  const getFilteredPatients = useCallback(() => {
    return allPatients
      .filter((patient) => {
        const matchesStaff = !staffFilter || staffFilter === "all" || patient.staffId === staffFilter;
        const matchesSearch = !nameFilter || 
          patient.name.toLowerCase().includes(nameFilter.toLowerCase());
        return matchesStaff && matchesSearch;
      })
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [allPatients, nameFilter, staffFilter]);

  const getPaginatedPatients = useCallback(() => {
    const filtered = getFilteredPatients();
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    return filtered.slice(start, end);
  }, [getFilteredPatients, currentPage, itemsPerPage]);

  const debouncedSearch = useCallback(
    debounce((value: string) => {
      setNameFilter(value);
      setCurrentPage(1);
    }, 300),
    []
  );

  const handleStaffFilter = (staffId: string) => {
    setStaffFilter(staffId);
    setCurrentPage(1);
  };

  useEffect(() => {
    fetchAllPatients();
    getStaffDetails();
  }, []);

  const filteredPatients = getFilteredPatients();
  const paginatedPatients = getPaginatedPatients();

  const handleModalClose = () => setModalOpen(false);

  const [isBulkUploadModalOpen, setBulkUploadModalOpen] = useState(false);

  const handleDownloadTemplate = () => {
    downloadClientTemplate();
  };

  const handleSubmitBulkUpload = async (file: File) => {
    try {
      const response = await bulkCreatePatients(file);
      toast({
        title: "Bulk upload successful",
        description: `${response.data.results.successCount} patients created successfully`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error: any) {
      toast({
        title: "Bulk upload failed",
        description: error?.response?.data?.message || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    setBulkUploadModalOpen(false);
  };

  const handleSubmitPatient = async (patientData: PatientDto) => {
    if (selectedPatient) {
      try {
        // Update existing patient
        await updatePatient(selectedPatient.id, {
          ...patientData,
        });
        toast({
          title: "Patient updated",
          description: `Patient ${patientData.name} has been updated successfully`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        handleModalClose();
      } catch (error: any) {
        toast({
          title: "Failed to update patient",
          description: error?.response?.data?.message || "An error occurred",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      try {
        // Create new patient
        const newPatient = await createPatient({
          ...patientData,
        });
        setAllPatients([...allPatients, newPatient.data]);
        toast({
          title: "Created new patient",
          description: `Patient ${newPatient.data.name} has been created successfully`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        handleModalClose();
      } catch (error: any) {
        toast({
          title: "Failed to create patient",
          description: error?.response?.data?.message || "An error occurred",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const handleEdit = (patientId: string) => {
    const patientToEdit = allPatients.find((patient) => patient.id === patientId);
    if (patientToEdit) {
      setSelectedPatient(patientToEdit);
      setModalOpen(true);
    }
  };

  const handleDelete = async (patientId: string) => {
    await deletePatient(patientId);
    setAllPatients(allPatients.filter((patient) => patient.id !== patientId));
  };

  return (
    <>
      <Header description="Manage clients" />
      <Container maxW={"9xl"}>
        <Stack>
          <Flex justifyContent={"space-between"}>
            <VStack align={"left"}>
              <Heading
                fontSize={{ base: "3xl", "2xl": "4xl" }}
                textTransform="uppercase"
              >
                Clients
              </Heading>
            </VStack>
            <Button
              size={{ base: "md", "2xl": "lg" }}
              onClick={() => {
                setSelectedPatient(null);
                setModalOpen(true);
              }}
              leftIcon={<Icon as={AddIcon} fontSize={"2xs"} />}
            >
              Create Client
            </Button>
          </Flex>
          <VStack spacing={4} align="stretch">
            <FormControl>
              <FormLabel>Search</FormLabel>
              <InputGroup size="lg">
                <InputLeftElement pointerEvents="none">
                  <FaSearch color="gray.300" />
                </InputLeftElement>
                <Input
                  placeholder="Search by name..."
                  onChange={(e) => debouncedSearch(e.target.value)}
                />
              </InputGroup>
            </FormControl>

            <HStack spacing={2} align="center">
              <Text fontSize={{ base: "sm", "2xl": "md" }} color="gray.600" minW="80px">
                Filter By Staff:
              </Text>
              <StaffSelect
                includeAllOption
                onSelectOption={handleStaffFilter}
                prefetchedStaffList={staffList}
                inputWidth="200px"
              />
            </HStack>
          </VStack>
          <Box mt={6}>
            <PatientTable
              patients={paginatedPatients}
              onEdit={handleEdit}
              onDelete={handleDelete}
              loading={loading}
            />
            <TablePagination
              currentPage={currentPage}
              totalRows={filteredPatients.length}
              currentPageRows={paginatedPatients.length}
              onPageChange={setCurrentPage}
              rowsPerPage={itemsPerPage}
              onChangeRowsPerPage={setItemsPerPage}
            />
          </Box>
        </Stack>
      </Container>

      <PatientModal
        initialData={selectedPatient}
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onSubmit={handleSubmitPatient}
        staffList={staffList}
      />

      <BulkUploadModal
        isOpen={isBulkUploadModalOpen}
        onClose={() => setBulkUploadModalOpen(false)}
        onSubmit={handleSubmitBulkUpload}
      />
    </>
  );
}
