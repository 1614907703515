import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Container,
  Divider,
  Flex,
  HStack,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getPatientById } from "../../api/patients";
import Header from "../../components/Head"; // Update path to Header component
import Activity from "../../components/Patients/Activity";
import ClientDetail from "../../components/Patients/ClientDetail";
import { TagPill } from "../../components/Tags/TagPill";
import { ROUTES, theme } from "../../constants";
import { PatientDto } from "../../types/patient";
import { TagDto } from "../../types/protocol";
import { formatDateToReadableString } from "../../utils/date";
import { parseISO } from "date-fns";
import { MetricConfiguration } from "../../components/MetricConfiguration";
import { HealthDataTracker } from "../../components/HealthDataTrackers";

export default function ViewPatientInfoPage() {
  const [loading, setLoading] = useState(true);
  const [patient, setPatient] = useState<PatientDto>();
  const { clientId } = useParams();

  useEffect(() => {
    if (!clientId) {
      return;
    }
    fetchPatientById(clientId);
  }, [clientId]);

  const fetchPatientById = async (patientId: string): Promise<boolean> => {
    try {
      setLoading(true);
      if (!patientId) {
        setLoading(false);
        return false;
      }
      const resp = await getPatientById(patientId);
      const patient = resp.data;
      if (patient) {
        setPatient({
          ...patient,
          dob:
            typeof patient.dob === "string"
              ? parseISO(patient.dob)
              : patient.dob,
        });
        setLoading(false);
        return true;
      }
    } catch (error) {
      console.error("Error fetching patient", error);
    } finally {
      setLoading(false);
      return false;
    }
  };

  if (loading || !patient) {
    return <div>Loading...</div>;
  }

  const renderTags = (tags: TagDto[]) => {
    if (!tags || tags.length === 0) {
      return (
        <TagPill key={"notags"} tagColor={"grey"} name={"No tags yet"} />
      )
    }
    const tag = tags[0];
    return (
      <Flex flexWrap={"wrap"} justifyContent={"center"} gap={1}>
        {tags.map((tag) => <TagPill key={tag.id} tagColor={tag.tagColor} name={tag.name} />)}
      </Flex>
    );
  };

  return (
    <>
      <Header description="View and manage your clients" />
      <Container maxW={"9xl"}>
        <VStack align={"start"}>
          <Breadcrumb mb={8}>
            <BreadcrumbItem>
              <BreadcrumbLink fontWeight={"light"} href={ROUTES.CLIENTS}>Clients</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink isCurrentPage href={`${ROUTES.CLIENTS}/${patient.id}`}>{patient.name}</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>

          <HStack w="full" justify={"space-between"} mb={4}>
            <Heading textTransform={"uppercase"} fontSize={"4xl"}>{patient.name}</Heading>
            <HStack>
              <Box>
                <Text fontWeight={"medium"} fontSize={{ base: "md", "2xl": "lg" }} color={`${theme}.600`} fontFamily={"heading"}>Last Activity</Text>
                <Text fontWeight={"medium"} fontSize={{ base: "md", "2xl": "lg" }} color={`${theme}.900`}>{formatDateToReadableString(patient.lastActivity!)}</Text>
              </Box>
              <Box maxW="xs">
                {renderTags(patient.tags!)}
              </Box>
            </HStack>
          </HStack>

          <Accordion allowToggle allowMultiple w="full" defaultIndex={[1]}>
            <AccordionItem>
              <AccordionButton>
                <Box as='span' flex='1' textAlign='left' py={2}>
                  <Heading fontWeight="medium" color={`${theme}.900`} fontSize={{ base: "lg", md: "xl" }}>Client Details</Heading>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel>
                <ClientDetail patient={patient} />
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionButton>
                <Box as='span' flex='1' textAlign='left' py={2}>
                  <Heading fontWeight="medium" color={`${theme}.900`} fontSize={{ base: "lg", md: "xl" }}>Monitor Vitals</Heading>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel>
                <MetricConfiguration
                  client={patient}
                />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </VStack>

        <Divider my={4} />
        <Tabs isFitted size={{ base: "md", "2xl": "lg" }}>
          <TabList>
            <Tab>Overview</Tab>
            <Tab>Repository</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <HealthDataTracker />
            </TabPanel>
            <TabPanel>
              <Activity
                patient={patient}
                refetchPatient={() => fetchPatientById(clientId!)}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Container>
    </>
  );
}
