import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Radio,
  Checkbox,
  Box,
  RadioGroup,
  Flex,
  TextProps
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { ClientSchemaFieldDto } from "../../../types/organization";
import { theme } from "../../../constants";

interface DropdownFieldProps {
  field: ClientSchemaFieldDto;
  initialValue?: string;
  handleInputChange: (key: string, value: string) => void;
}

export const DropdownField = ({
  field,
  initialValue,
  handleInputChange,
}: DropdownFieldProps) => {
  // Convert initial value to appropriate type
  const getInitialValue = () => {
    if (!initialValue) return field.isMultiSelect ? [] : '';
    return field.isMultiSelect ? initialValue.split(',').filter(Boolean) : initialValue;
  };

  const [selectedValue, setSelectedValue] = useState<string | string[]>(getInitialValue());

  const handleChange = (value: string | string[]) => {
    setSelectedValue(value);
    const stringValue = Array.isArray(value) ? value.join(',') : value;
    handleInputChange(field.key, stringValue);
  };

  // Helper to get display text for selected values
  const getDisplayText = () => {
    if (!selectedValue || (Array.isArray(selectedValue) && selectedValue.length === 0)) {
      return "Select an option";
    }
    if (Array.isArray(selectedValue)) {
      return selectedValue.join(", ");
    }
    return selectedValue;
  };

  const buttonStyles = {
    variant: "unstyled" as const,
    fontSize: { base: "md", "2xl": "lg" },
    fontWeight: "medium",
    color: selectedValue && 
      (Array.isArray(selectedValue) ? selectedValue.length > 0 : selectedValue) 
      ? `${theme}.900` 
      : "gray.500",
    _hover: { color: `${theme}.700` },
    height: "2rem",
    padding: 0,
    borderBottom: "1px solid",
    borderColor: "inherit",
    borderRadius: 0,
    _focus: { boxShadow: "none" },
    width: "full"
  };

  const menuButtonContent = (
    <Flex w="full" align="center" justify="space-between">
        <Box noOfLines={1} textAlign="left" flex="1">
          {getDisplayText()}
        </Box>
        <Box>
          <ChevronDownIcon />
        </Box>
    </Flex>
  );

  if (field.isMultiSelect) {
    return (
      <Box w="full">
        <Menu closeOnSelect={false}>
          <MenuButton as={Button} {...buttonStyles}>
            {menuButtonContent}
          </MenuButton>
          <MenuList maxH="300px" overflowY="auto">
            {field.dropdownOptions!.map((value, index) => (
              <MenuItem 
                key={index} 
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  const currentValues = Array.isArray(selectedValue) ? selectedValue : [];
                  const newValue = currentValues.includes(value)
                    ? currentValues.filter(v => v !== value)
                    : [...currentValues, value];
                  handleChange(newValue);
                }}
                closeOnSelect={false}
              >
                <Checkbox
                  isChecked={Array.isArray(selectedValue) && selectedValue.includes(value)}
                  size={{ base: "md", "2xl": "lg" }}
                  colorScheme={theme}
                  pointerEvents="none"
                >
                  {value}
                </Checkbox>
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Box>
    );
  }

  return (
    <Box w="full">
      <Menu>
        <MenuButton as={Button} {...buttonStyles}>
          {menuButtonContent}
        </MenuButton>
        <MenuList maxH="300px" overflowY="auto">
          <RadioGroup value={selectedValue as string} onChange={handleChange}>
            {field.dropdownOptions!.map((value, index) => (
              <MenuItem 
                key={index} 
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleChange(value);
                }}
              >
                <Radio
                  value={value}
                  size={{ base: "md", "2xl": "lg" }}
                  colorScheme={theme}
                  isChecked={selectedValue === value}
                  pointerEvents="none"
                >
                  {value}
                </Radio>
              </MenuItem>
            ))}
          </RadioGroup>
        </MenuList>
      </Menu>
    </Box>
  );
};
