import axios from "./axios/staffAuth";
import { ClientSchemaFieldDto, OrganizationDto, CreateOrganizationDto } from "../types/organization";

const API_URL = process.env.REACT_APP_API_URL || "";

export interface ApiResponse<T> {
  data?: T;
  message?: string;
}

export const createOrganization = async (data: CreateOrganizationDto): Promise<ApiResponse<OrganizationDto>> => {
  try {
    const response = await axios.post<OrganizationDto>(
      `${API_URL}/organization`,
      data
    );
    return { data: response.data };
  } catch (error: any) {
    return {
      message: error.response?.data?.message || "Failed to create organization",
    };
  }
};

export const getOrganizations = async (): Promise<ApiResponse<OrganizationDto[]>> => {
  try {
    const response = await axios.get<OrganizationDto[]>(
      `${API_URL}/organization`
    );
    return { data: response.data };
  } catch (error: any) {
    return {
      message: error.response?.data?.message || "Failed to fetch organizations",
    };
  }
};

export const updateOrganization = async (id: string, data: Partial<OrganizationDto>): Promise<ApiResponse<OrganizationDto>> => {
  try {
    const response = await axios.put<OrganizationDto>(
      `${API_URL}/organization/${id}`,
      data
    );
    return { data: response.data };
  } catch (error: any) {
    return {
      message: error.response?.data?.message || "Failed to update organization",
    };
  }
};

export const updateOrganizationClientSchema = async (id: string, clientSchema: ClientSchemaFieldDto[]): Promise<ApiResponse<OrganizationDto>> => {
  try {
    const response = await axios.put<OrganizationDto>(
      `${API_URL}/organization/${id}/client-schema`,
      { clientSchema }
    );
    return { data: response.data };
  } catch (error: any) {
    return {
      message: error.response?.data?.message || "Failed to update client schema",
    };
  }
};
