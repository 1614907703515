import { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Layout from "./components/Layout";
import { StaffProtectedRoute } from "./components/ProtectedRoute/staff";
import { ClientProtectedRoute } from "./components/ProtectedRoute/client";
import ScrollToTop from "./components/ScrollToTop";
import { ROUTES } from "./constants";
import CreateFormPage from "./pages/CreateFormPage";
import FormPage from "./pages/FormPage";
import ForgotPasswordPage from "./pages/ForgotPassword";
import Form from "./pages/Form";
import Login from "./pages/Login";
import PageNotFound from "./pages/PageNotFound";
import ResetPasswordPage from "./pages/PasswordResetPage";
import PatientResponseView from "./pages/PatientResponseView";
import Patients from "./pages/Patients";
import ViewPatientInfoPage from "./pages/ViewPatientInfo";
import AllProtocolsPage from "./pages/AllProtocolsPage";
import ReportPage from "./pages/ReportPage";
import ClientLogin from "./pages/ClientLogin";
import ClientRegister from "./pages/ClientRegister";
import ClientResetPasscode from "./pages/ClientResetPasscode";
import { CreateProtocolPage } from "./pages/CreateProtocolPage";
import { ViewProtocolPage } from "./pages/ViewProtocolPage";
import ViewFormPage from "./pages/ViewFormPage";
import ClientPass from "./pages/ClientPass";
import ViewMetricData from "./pages/ViewMetricData";
import MetricForm from "./pages/MetricForm";
import AdminPage from "./pages/Admin";
import { AdminProtectedRoute } from "./components/ProtectedRoute/admin";

export const App = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (window.location.pathname === "/") {
      navigate(ROUTES.CLIENTS);
    }
  }, [navigate]);

  return (
    <ScrollToTop>
      <Routes>
        <Route path={ROUTES.LOGIN} element={<Login />} />
        {/* <Route path={ROUTES.SIGN_UP} element={<SignUpPage />} /> */}
        <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPasswordPage />} />
        <Route path={ROUTES.RESET_PASSWORD} element={<ResetPasswordPage />} />

        <Route
          path={`${ROUTES.CLIENTS}/:clientId${ROUTES.LOGIN}`}
          element={<ClientLogin />}
        />
        <Route
          path={`${ROUTES.CLIENTS}/:clientId${ROUTES.REGISTER}`}
          element={<ClientRegister />}
        />
        <Route
          path={`${ROUTES.CLIENTS}/:clientId${ROUTES.RESET_PASSCODE}`}
          element={<ClientResetPasscode />}
        />
        <Route
          path={`${ROUTES.CLIENTS}/:clientId${ROUTES.CLIENT_PASS}`}
          element={
            <ClientProtectedRoute>
              <ClientPass />
            </ClientProtectedRoute>
          }
        />
        <Route
          path={`${ROUTES.CLIENTS}/:clientId${ROUTES.VIEW_METRIC_DATA}`}
          element={
            <ClientProtectedRoute>
              <ViewMetricData />
            </ClientProtectedRoute>
          }
        />
        <Route
          path={`${ROUTES.FORM}/:formId/:clientId`}
          element={
            <ClientProtectedRoute>
              <Form />
            </ClientProtectedRoute>
          }
        />
        <Route
          path={`${ROUTES.CLIENTS}/:clientId${ROUTES.FORM_RESPONSE}/:flowId/view`}
          element={
            <ClientProtectedRoute>
              <PatientResponseView />
            </ClientProtectedRoute>
          }
        />
        <Route
          path={`${ROUTES.METRIC_FORM}/:metricId/:clientId`}
          element={
            <ClientProtectedRoute>
              <MetricForm />
            </ClientProtectedRoute>
          }
        />
        <Route element={<Layout />}>
          <Route
            path={ROUTES.CLIENTS}
            element={
              <StaffProtectedRoute>
                <Patients />
              </StaffProtectedRoute>
            }
          />
          <Route
            path={ROUTES.FORMS_LIST}
            element={
              <StaffProtectedRoute>
                <FormPage />
              </StaffProtectedRoute>
            }
          />
          <Route
            path={ROUTES.CREATE_FORM}
            element={
              <StaffProtectedRoute>
                <CreateFormPage />
              </StaffProtectedRoute>
            }
          />
          <Route
            path={`${ROUTES.FORMS_LIST}/:flowId`}
            element={
              <StaffProtectedRoute>
                <ViewFormPage />
              </StaffProtectedRoute>
            }
          />
          <Route
            path={`${ROUTES.CLIENTS}/:clientId`}
            element={
              <StaffProtectedRoute>
                <ViewPatientInfoPage />
              </StaffProtectedRoute>
            }
          />

          <Route
            path={`${ROUTES.CLIENTS}/:clientId${ROUTES.FORM_RESPONSE}/:flowId`}
            element={
              <StaffProtectedRoute>
                <PatientResponseView isAdminView />
              </StaffProtectedRoute>
            }
          />
          <Route
            path={`${ROUTES.PROTOCOLS}`}
            element={
              <StaffProtectedRoute>
                <AllProtocolsPage />
              </StaffProtectedRoute>
            }
          />
          <Route
            path={`${ROUTES.PROTOCOLS}/:protocolId`}
            element={
              <StaffProtectedRoute>
                <ViewProtocolPage />
              </StaffProtectedRoute>
            }
          />
          <Route
            path={`${ROUTES.PROTOCOLS}/new`}
            element={
              <StaffProtectedRoute>
                <CreateProtocolPage />
              </StaffProtectedRoute>
            }
          />
          <Route
            path={ROUTES.ADMIN}
            element={
              <StaffProtectedRoute>
                <AdminProtectedRoute>
                  <AdminPage />
                </AdminProtectedRoute>
              </StaffProtectedRoute>
            }
          />
        </Route>
        <Route
          path={`${ROUTES.REPORT}/:docId`}
          element={
            <StaffProtectedRoute>
              <ReportPage />
            </StaffProtectedRoute>
          }
        />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </ScrollToTop>
  );
};
