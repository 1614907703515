import { ClientSchemaFieldDto } from "./organization";

export enum StaffAuthLevel {
  BASIC = "BASIC",
  ADMIN = "ADMIN",
}

export interface StaffDto {
  id: string;
  name: string;
  email: string;
  organizationId: string;
  organizationName: string;
  authLevel?: StaffAuthLevel;
}

export interface CreateStaffDto {
  name: string;
  email: string;
  password: string;
  organizationId?: string;
  authLevel?: StaffAuthLevel;
}

export interface UpdateStaffDto {
  name?: string;
  email?: string;
  password?: string;
  organizationId?: string;
  authLevel?: StaffAuthLevel;
}

export interface GetStaffListResp {
  data: StaffDto[];
  total: number;
  message?: string;
}

export interface StaffWithOrganizationDto extends StaffDto {
  organizationClientSchema: ClientSchemaFieldDto[] | null;
}
