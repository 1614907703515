import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Card,
  Checkbox,
  Container,
  Divider,
  Flex,
  FormControl,
  HStack,
  Heading,
  IconButton,
  Input,
  Link,
  Select,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaPencilAlt, FaPlus, FaTrash } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { deleteProtocol, getAllTags, getProtocolActivitiesByProtocolId, getProtocolById, updateProtocol } from "../../api/protocol";
import { ROUTES, theme } from "../../constants";
import { ConditionDto, CreateOrUpdateProtocolDto, Operation, PatientTagDto, Property, ProtocolActivityDto, ProtocolStatus, TagDto } from "../../types/protocol";
import { formatEnumValue } from "../../utils/strings";
import Header from "../../components/Head";
import { AiOutlineMessage, AiOutlineQuestionCircle, AiOutlineSync, AiOutlineTag } from "react-icons/ai";
import { tagColorMap, TagPill } from "../../components/Tags/TagPill";
import { AutoComplete, AutoCompleteInput, AutoCompleteItem, AutoCompleteList } from "@choc-ui/chakra-autocomplete";
import { formatDate, formatDateToReadableString } from "../../utils/date";
import axios, { AxiosError } from "axios";

const ActivityCard = (activity: ProtocolActivityDto) => {
  if (activity.status == ProtocolStatus.ADDED_TAG && activity.tag) {
    return (
      <>
        <Text fontSize={{ base: "md", "2xl": "lg" }} color={`${theme}.800`}>
          <AiOutlineTag />
        </Text>
        <Text ml={4} fontSize={{ base: "md", "2xl": "lg" }} color={`${theme}.800`} display={"inline"}>
          Added tag
        </Text>
        <TagPill tagColor={activity.tag.color} name={activity.tag.name} />
        <Text fontSize={{ base: "md", "2xl": "lg" }} color={`${theme}.800`} display={"inline"}>
          to{" "}
          <Link fontWeight={"bold"} textDecoration={"underline"} href={`${ROUTES.CLIENTS}/${activity.client.id}`}>
            {activity.client.name}
          </Link>
        </Text>
      </>
    )
  } else if (activity.status == ProtocolStatus.REMOVED_TAG && activity.tag) {
    return (
      <>
        <Text fontSize={{ base: "md", "2xl": "lg" }} color={`${theme}.800`}>
          <AiOutlineTag />
        </Text>
        <Text ml={4} fontSize={{ base: "md", "2xl": "lg" }} color={`${theme}.800`} display={"inline"}>
          Removed tag
        </Text>
        <TagPill tagColor={activity.tag.color} name={activity.tag.name} />
        <Text fontSize={{ base: "md", "2xl": "lg" }} color={`${theme}.800`} display={"inline"}>
          from{" "}
          <Link fontWeight={"bold"} textDecoration={"underline"} href={`${ROUTES.CLIENTS}/${activity.client.id}`}>
            {activity.client.name}
          </Link>
        </Text>
      </>
    )
  } else if (activity.status == ProtocolStatus.SENT_MESSAGE) {
    return (
      <>
        <Text fontSize={{ base: "md", "2xl": "lg" }} color={`${theme}.800`}>
          <AiOutlineMessage />
        </Text>
        <Text ml={4} fontSize={{ base: "md", "2xl": "lg" }} color={`${theme}.800`} display={"inline"}>
          Sent message
        </Text>
        <Text fontSize={{ base: "md", "2xl": "lg" }} color={`${theme}.800`} display={"inline"}>
          to{" "}
          <Link fontWeight={"bold"} textDecoration={"underline"} href={`${ROUTES.CLIENTS}/${activity.client.id}`}>
            {activity.client.name}
          </Link>
        </Text>
      </>
    )
  } else {
    return null;
  }
}

export const ViewProtocolPage = () => {
  const navigate = useNavigate();
  const { protocolId } = useParams();
  const toast = useToast();

  // used for editiing protocol name and description
  const [isEditingName, setIsEditingName] = useState<boolean>(false);

  // editing protocol tags
  const [allTags, setAllTags] = useState<TagDto[]>([]);
  const [tags, setTags] = useState<TagDto[]>([]);

  // Initial state for protocol details
  const [sendMessage, setSendMessage] = useState<boolean>(false);
  const [protocol, setProtocol] = useState<CreateOrUpdateProtocolDto>({
    id: "",
    name: "",
    description: "",
    removeWhenNotMet: true,
    conditions: {
      type: "AND",
      conditions: [
        {
          property: "",
          operation: Operation.GT,
          value: "",
        } as ConditionDto,
      ],
    },
    existingTagIds: [],
    newTags: [],
    message: undefined,
  });
  const [activities, setActivities] = useState<ProtocolActivityDto[]>([]);

  // Load all tags and set them
  useEffect(() => {
    async function getTags() {
      const tagsResp = await getAllTags();
      setAllTags(tagsResp.data ?? []);
    }

    getTags();
  }, []);

  // Handle input changes for non-nested fields
  const handleChange = (e: any) => {
    const { name, value, type, checked } = e.target;
    setProtocol((prevDetails) => ({
      ...prevDetails,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const fetchProtocol = async (protocolId: string) => {
    const resp = await getProtocolById(protocolId);
    if (resp.data) {
      const {tags, ...protocolWithoutTags } = resp.data;
      setProtocol((prevDetails) => ({
        ...prevDetails,
        ...protocolWithoutTags,
        removeWhenNotMet: true
      }));
      setTags(tags);
      setSendMessage(resp.data.message != null);
    }
  };

  const fetchProtocolActivities = async (protocolId: string) => {
    const resp = await getProtocolActivitiesByProtocolId(protocolId);
    if (resp.data) {
      // Sort activities by createdAt in reverse chronological order
      const sortedActivities = [...resp.data].sort((a, b) => 
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
      setActivities(sortedActivities);
    }
  }

  useEffect(() => {
    if (protocolId) {
      fetchProtocol(protocolId);
      fetchProtocolActivities(protocolId);
    }
  }, [protocolId]);

  // Add a new condition to the tag logic
  const addCondition = () => {
    const newCondition = {
      property: "",
      operation: Operation.GT,
      value: "",
    };
    setProtocol((prevDetails) => ({
      ...prevDetails,
      conditions: {
        ...prevDetails.conditions,
        conditions: [
          ...prevDetails.conditions.conditions,
          newCondition,
        ],
      },
    }));
  };

  // Handle input changes for dynamic conditions
  const handleConditionChange = (
    index: number,
    field: string,
    value: string
  ) => {
    const newConditions = [...protocol.conditions.conditions];
    newConditions[index] = { ...newConditions[index], [field]: value };
    setProtocol((prevDetails) => ({
      ...prevDetails,
      conditions: {
        ...prevDetails.conditions,
        conditions: newConditions,
      },
    }));
  };

  // Remove a condition from the tag logic
  const removeCondition = (index: number) => {
    const newConditions = [...protocol.conditions.conditions];
    newConditions.splice(index, 1);
    setProtocol((prevDetails) => ({
      ...prevDetails,
      conditions: {
        ...prevDetails.conditions,
        conditions: newConditions,
      },
    }));
  };

  const handleSelectTag = (tag: TagDto, index: number) => {
    setTags(prevTags => {
      // Make a shallow copy of the previous tags array
      const newTags = [...prevTags];
      // Update the specific index with the new tag data
      newTags[index] = tag;
      return newTags;
    });
  };

  const handleAddCustomTag = () => {
    setTags([...tags, { name: "", tagColor: "" }])
  };

  const handleTagInputChange = (value: string, index: number) => {
    setTags(prevTags => {
      // Make a shallow copy of the previous tags array
      const newTags = [...prevTags];
      // Update the specific index with the new tag data
      newTags[index] = { ...newTags[index], name: value };
      return newTags;
    });
  };

  const handlePriorityChange = (value: string, index: number) => {
    setTags(prevTags => {
      // Make a shallow copy of the previous tags array
      const newTags = [...prevTags];
      // Update the specific index with the new tag data
      newTags[index] = { ...newTags[index], tagColor: value };
      return newTags;
    });
  };

  const removeTag = (index: number) => {
    setTags(prevTags => prevTags.filter((_, i) => i !== index));
  };

  const handleSave = async () => {
    try {
      // convert tags to protocol DTO
      tags.forEach(tag => {
        if (tag.id != null) {
          if (!protocol.existingTagIds.includes(tag.id)) {
            protocol.existingTagIds.push(tag.id);
          }
        } else {
          protocol.newTags.push(tag);
        }
      });

      await updateProtocol(protocol.id!, protocol);
      toast({
        title: "Protocol saved successfully!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      
      navigate(ROUTES.PROTOCOLS);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const error = e as AxiosError;
        toast({
          title: "Error",
          description: e.response?.data.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: "An error occurred. Please try again.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
      return;
    }
  };

  const handleDelete = async (tagId: string) => {
    const resp = await deleteProtocol(tagId);
    if (resp.status === 200) {
      navigate(ROUTES.PROTOCOLS);
    } else {
      toast({
        title: "Error",
        description: "An error occurred. Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleDeleteMessage = () => {
    setSendMessage(false);
    setProtocol({ ...protocol, message: undefined });
  }

  const handleAddMessage = () => {
    setSendMessage(true);
  }

  return (
    <>
      <Container maxW={"9xl"}>
        <Header description="Create Tag" />

        <VStack align={"start"} spacing={6} w="full">
          <Breadcrumb mb={2}>
            <BreadcrumbItem>
              <BreadcrumbLink fontWeight={"light"} href={ROUTES.PROTOCOLS}>Protocols</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              <Text>{protocol.name}</Text>
            </BreadcrumbItem>
          </Breadcrumb>

          <VStack align={"start"} w="full">
            <HStack w="full">
              <Box w="min" bg={`gray.100`} borderRadius={"md"} py={1} px={2}>
                <AiOutlineSync size={32} color="gray" />
              </Box>
              <Heading
                fontSize={{ base: "3xl", "2xl": "4xl" }}
                textTransform={"uppercase"}
              >
                {protocol.name}
              </Heading>
              <Spacer />
              <Button
                bg="red.500"
                _hover={{
                  bg: "red.700",
                }}
                size={{ base: "md", "2xl": "lg" }}
                fontSize={{ base: "md", "2xl": "lg" }}
                onClick={() => handleDelete(protocol.id!)}
              >
                Delete protocol
              </Button>
            </HStack>
            <Text 
              maxW="80%"
              fontSize={{ base: "lg", "2xl": "xl" }}
              fontWeight="normal"
              color={`${theme}.800`}
            >
              Created by {protocol.createdBy}
            </Text>
          </VStack>

          <Divider />

          <Tabs w="full" isFitted size={{ base: "md", "2xl": "lg" }}>
            <TabList>
              <Tab>Manage</Tab>
              <Tab>Activity</Tab>
            </TabList>
            <TabPanels w="full">
              <TabPanel>
                <Flex justifyContent={"center"} w="full">
                  <VStack mt={16} spacing={4} align={"start"} w="70%">
                    <HStack>
                      {isEditingName ? (
                        <Input
                          value={protocol.name}
                          name="name"
                          onChange={handleChange}
                          onBlur={() => setIsEditingName(false)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              setIsEditingName(false);
                            }
                          }}
                          autoFocus
                          fontSize={{ base: "3xl", "2xl": "4xl" }}
                          fontWeight="bold"
                          variant="unstyled"
                          fontFamily={"heading"}
                          minW={{ base: "xl", "2xl": "2xl" }}
                          color={`${theme}.700`}
                        />
                      ) : (
                        <>
                          <Heading
                            fontSize={{ base: "3xl", "2xl": "4xl" }}
                            onDoubleClick={() => setIsEditingName(true)}
                            color={`${theme}.700`}
                          >
                            {protocol.name || "Name your Protocol"}
                          </Heading>
                          <FaPencilAlt
                            size={16}
                            style={{ marginLeft: '8px', cursor: 'pointer' }}
                            onClick={() => setIsEditingName(true)}
                          />
                        </>
                      )}
                    </HStack>
                    <Card width="full" p={3}>
                      <HStack align={"start"} justify={"start"} w="full" spacing={4}>
                        <Box w="min" bg={`gray.100`} borderRadius={"md"} py={1} px={2}>
                          <AiOutlineSync size={24} />
                        </Box>
                        <VStack align={"start"} w="full" spacing={3}>
                          <HStack w="full" spacing={3}>
                            <Text fontSize={{ base: "md", "2xl": "lg" }} color={`${theme}.800`} fontWeight={"medium"}>
                              Add protocol with description:
                            </Text>
                            <Input
                              placeholder="Tag Name"
                              name="description"
                              value={protocol.description}
                              size={{ base: "sm", "2xl": "md" }}
                              fontSize={{ base: "md", "2xl": "lg" }}
                              color={`${theme}.800`}
                              w={"60%"}
                              onChange={handleChange}
                            />
                          </HStack>
                        </VStack>
                      </HStack>
                    </Card>

                    {tags.length > 0 && tags.map((tag, index) => (
                      <Card width="full" p={3}>
                        <HStack align={"start"} justify={"start"} w="full" spacing={4}>
                          <Box w="min" bg={`gray.100`} borderRadius={"md"} py={1} px={2}>
                            <AiOutlineTag size={24} />
                          </Box>
                          <VStack align={"start"} w="full" spacing={3}>
                            <HStack w="full" spacing={3}>
                              <Text fontSize={{ base: "md", "2xl": "lg" }} color={`${theme}.800`} fontWeight={"medium"}>
                                Add tag(s) to client:
                              </Text>
                              {tag.id ? (
                                <TagPill tagColor={tag.tagColor} name={tag.name} />
                              ) : (
                                <>
                                  <FormControl w="30%" isRequired>
                                    <AutoComplete
                                      openOnFocus
                                      freeSolo
                                    >
                                      <AutoCompleteInput
                                        placeholder="Find or add tag"
                                        fontSize={{ base: "md", "2xl": "lg" }}
                                        size={{ base: "sm", "2xl": "md" }}
                                        color={`${theme}.800`}
                                        onChange={(e) => handleTagInputChange(e.target.value, index)}
                                      />
                                      <AutoCompleteList>
                                        {allTags.length > 0 &&
                                          allTags.map((tag, idx) => (
                                            <AutoCompleteItem
                                              key={`option-${idx}`}
                                              value={tag.id}
                                              label={tag.name}
                                              onClick={() => handleSelectTag({ id: tag.id!, name: tag.name, tagColor: tag.tagColor }, index)}
                                            >
                                              <TagPill tagColor={tag.tagColor} name={tag.name} />
                                            </AutoCompleteItem>
                                          ))}
                                      </AutoCompleteList>
                                    </AutoComplete>
                                  </FormControl>
                                  <Text fontSize={{ base: "md", "2xl": "lg" }} color={`${theme}.800`} fontWeight={"medium"}>
                                    with
                                  </Text>
                                  <FormControl 
                                    w="20%"
                                    isRequired
                                  >
                                    <Select
                                      placeholder="Priority"
                                      size={{ base: "sm", "2xl": "md" }}
                                      fontSize={{ base: "md", "2xl": "lg" }}
                                      color={`${theme}.800`}
                                      onChange={(e) => handlePriorityChange(e.target.value, index)}
                                    >
                                      {Object.entries(tagColorMap).map(([key, value]) => (
                                        <option key={key} value={key}>
                                          {value.label}
                                        </option>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </>
                              )}
                              <IconButton
                                colorScheme="gray"
                                onClick={() => removeTag(index)}
                                icon={<FaTrash />}
                                aria-label={"delete"}
                                isDisabled={tags.length <= 1 && protocol.message == null}
                              />
                            </HStack>
                          </VStack>
                        </HStack>
                      </Card>
                    ))}
                    <Button
                      onClick={handleAddCustomTag}
                      leftIcon={<FaPlus />}
                      variant={"ghost"}
                      color={`${theme}.700`}
                      size={{ base: "md", "2xl": "lg" }}
                    >
                      Add Tags
                    </Button>

                    {
                      sendMessage ? (
                        <Card w="full" p={3}>
                          <VStack alignItems={"flex-start"} w="full">
                            <HStack mb={3} w="full">
                              <Box w="min" bg={`gray.100`} borderRadius={"md"} py={1} px={2}>
                                <AiOutlineMessage size={24} />
                              </Box>
                              <Text fontSize={{ base: "md", "2xl": "lg" }} color={`${theme}.800`} fontWeight={"medium"}>
                                Send message
                              </Text>
                              <Spacer />
                              <IconButton
                                colorScheme="gray"
                                onClick={handleDeleteMessage}
                                aria-label="deleteMessage"
                                icon={<FaTrash />}
                                isDisabled={tags.length == 0}
                              />
                            </HStack>
                            <Textarea
                              placeholder="Enter message..."
                              mb={3}
                              fontSize={{ base: "md", "2xl": "lg" }}
                              fontWeight="normal"
                              color={`${theme}.700`}
                              value={protocol.message}
                              rows={4}
                              resize="vertical"
                              onChange={(e) => setProtocol({ ...protocol, message: e.target.value })}
                            />
                          </VStack>
                        </Card>
                      ) : (
                        <Button
                          onClick={handleAddMessage}
                          leftIcon={<FaPlus />}
                          variant={"ghost"}
                          color={`${theme}.700`}
                          size={{ base: "md", "2xl": "lg" }}
                        >
                          Add Message
                        </Button>
                      )
                    }

                    <Text fontSize={{ base: "lg", "2xl": "xl" }} color={`${theme}.700`} fontWeight={"medium"}>
                      when
                    </Text>

                    {/* Dynamic conditions */}
                    {protocol.conditions.conditions.map((condition, index) => (
                      <Card width="full" p={3}>
                        <HStack key={index}>
                          {index === 0 ? (
                            <Box w="min" bg={`gray.100`} borderRadius={"md"} py={1} px={2}>
                              <AiOutlineQuestionCircle size={24} />
                            </Box>
                          ) : (
                            <Text w={200}>And</Text>
                          )}
                          <Select
                            placeholder="Property"
                            required
                            value={condition.property}
                            onChange={(e) =>
                              handleConditionChange(index, "property", e.target.value)
                            }
                            size={{ base: "sm", "2xl": "md" }}
                            fontSize={{ base: "md", "2xl": "lg" }}
                            color={`${theme}.800`}
                          >
                            {Object.keys(Property)
                              .sort()
                              .map((key: string) => (
                                <option value={Property[key as keyof typeof Property]}>
                                  {formatEnumValue(Property[key as keyof typeof Property])}
                                </option>
                              ))}
                          </Select>

                          <Select
                            required
                            value={condition.operation}
                            onChange={(e) =>
                              handleConditionChange(index, "operation", e.target.value)
                            }
                            size={{ base: "sm", "2xl": "md" }}
                            fontSize={{ base: "md", "2xl": "lg" }}
                            color={`${theme}.800`}
                          >
                            {/* Hardcoded operation options based on the Operation enum */}
                            <option value={Operation.LT}>Is Less Than</option>
                            <option value={Operation.LTE}>Is Less Than or Equal To</option>
                            <option value={Operation.EQ}>Is Equal To</option>
                            <option value={Operation.GTE}>
                              Is Greater Than or Equal To
                            </option>
                            <option value={Operation.GT}>Is Greater Than</option>
                          </Select>

                          <Input
                            required
                            placeholder="Value"
                            value={condition.value}
                            onChange={(e) =>
                              handleConditionChange(index, "value", e.target.value)
                            }
                            size={{ base: "sm", "2xl": "md" }}
                            fontSize={{ base: "md", "2xl": "lg" }}
                            color={`${theme}.800`}
                          />

                          <IconButton
                            colorScheme="gray"
                            onClick={() => removeCondition(index)}
                            icon={<FaTrash />}
                            aria-label={"delete"}
                            isDisabled={index === 0}
                          />
                        </HStack>
                      </Card>
                    ))}

                    <Button
                      onClick={addCondition}
                      leftIcon={<FaPlus />}
                      variant={"ghost"}
                      color={`${theme}.700`}
                      size={{ base: "md", "2xl": "lg" }}
                    >
                      Add Condition
                    </Button>

                    <Checkbox
                      isChecked={true}
                      name="removeWhenNotMet"
                      onChange={handleChange}
                      size={{ base: "md", "2xl": "lg" }}
                      color={`${theme}.700`}
                      isDisabled={true}
                    >
                      Remove tag when conditions are no longer met
                    </Checkbox>

                    <Button onClick={handleSave} mt={4} type="submit" w="full" size={{ base: "md", "2xl": "lg" }}>
                      Save
                    </Button>
                  </VStack>
                </Flex>
              </TabPanel>

              <TabPanel>
                <VStack spacing={4}>
                  {activities.map((activity, index) => (
                    <Card width="full" p={3}>
                      <HStack key={index}>
                        <ActivityCard {...activity} />
                        <Spacer />
                        <Text fontSize={{ base: "md", "2xl": "lg" }} fontWeight={"normal"} color={`${theme}.800`}>
                          {formatDateToReadableString(activity.createdAt)}
                        </Text>
                      </HStack>
                    </Card>
                  ))}
                </VStack>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </VStack>
      </Container>
    </>
  );
};
