import { CreateOrUpdateProtocolDto, GetProtocolDto, ProtocolActivityDto, ProtocolDto, ProtocolQueryParams, TagDto } from "../types/protocol";
import axios from "./axios/staffAuth";

const API_URL = process.env.REACT_APP_API_URL || "";

// Queries

// Fetches all protocols
export const getAllProtocols = async (params: ProtocolQueryParams) => {
  return axios.get<{ data: ProtocolDto[]; total: number }>(`${API_URL}/protocol`,
    { params }
  );
};

// Fetches a single protocol by ID
export const getProtocolById = (id: string) => {
  return axios.get<GetProtocolDto>(`${API_URL}/protocol/${id}`);
};

// Fetches all protocol activity by ID
export const getProtocolActivitiesByProtocolId = (id: string) => {
  return axios.get<ProtocolActivityDto[]>(`${API_URL}/protocol/activity/${id}`);
}

// Fetches all protocol activity by ID
export const getProtocolActivitiesByPatientId = (id: string) => {
  return axios.get<ProtocolActivityDto[]>(`${API_URL}/protocol/activity/patient/${id}`);
}

// Creates a new protocol
export const createProtocol = (protocol: CreateOrUpdateProtocolDto) => {
  return axios.post(`${API_URL}/protocol`, protocol);
};

// Updates an existing protocol
export const updateProtocol = (id: string, protocol: CreateOrUpdateProtocolDto) => {
  return axios.put(`${API_URL}/protocol/${id}`, protocol);
};

// Deletes a protocol by ID
export const deleteProtocol = (id: string) => {
  return axios.delete(`${API_URL}/protocol/${id}`);
};

// Gets all tags for the current staff
export const getAllTags = () => {
  return axios.get<TagDto[]>(`${API_URL}/protocol/all-tags`);
}
