import { Tag, TagProps } from "@chakra-ui/react";

export const tagColorMap: { [key: string]: { label: string, text: string; bg: string } } = {
  red: {
    label: "Red",
    text: "white",
    bg: "#ff5449",
  },
  yellow: {
    label: "Yellow",
    text: "white",
    bg: "#e4d00a",
  },
  green: {
    label: "Green",
    text: "white",
    bg: "#42b14a",
  },
  grey: {
    label: "Grey",
    text: "black",
    bg: "#e6e6e6",
  },
};

interface TagPillProps {
  tagColor: string;
  name: string;
}

export const TagPill = ({
  tagColor,
  name,
  ...others
}: TagPillProps & TagProps) => {
  tagColor = tagColor.toLowerCase();
  return (
    <Tag
      backgroundColor={tagColorMap[tagColor]?.bg}
      color={tagColorMap[tagColor]?.text}
      {...others}
    >
      {name}
    </Tag>
  );
};
