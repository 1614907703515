import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Select,
  SimpleGrid,
  useToast,
} from "@chakra-ui/react";
import { SingleDatepicker } from "chakra-dayzed-datepicker";
import { useEffect, useState } from "react";
import { updatePatient } from "../../../api/patients";
import { PatientDto } from "../../../types/patient";
import { getProviderFriendlyName, toTitleCase } from "../../../utils";
import { messagingApps, theme } from "../../../constants";
import { useStaffStore } from "../../../store/staffStore";
import CustomClientField from "../CustomClientField/CustomClientField";
import { ProviderConfig, ProviderType } from "../../../constants/provider";
import { PhoneNumberUtil } from "google-libphonenumber";
import { getCountries, getCountryCallingCode } from "react-phone-number-input";
import { StaffSelect } from "../../StaffSelect";

interface ClientDetailProps {
  patient: PatientDto;
}

const ClientDetail = ({ patient }: ClientDetailProps) => {
  const { currentStaff } = useStaffStore();
  const [initialPatient, setInitialPatient] = useState<PatientDto>(patient);
  const [updatedPatient, setUpdatedPatient] = useState<PatientDto>(patient);
  const toast = useToast();

  // phone number is split into two fields (country code and number), which
  // are concatenated at the patient level
  const countries = getCountries();
  const phoneUtil = PhoneNumberUtil.getInstance();
  const [patientPhoneCountryCode, setPatientPhoneCountryCode] = useState<string>(`+${phoneUtil.parse(patient.whatsappPhoneNum, "SG").getCountryCode()}`);
  const [patientPhoneNumber, setPatientPhoneNumber] = useState<string>(phoneUtil.parse(patient.whatsappPhoneNum, "SG").getNationalNumber()?.toString() || "");
  useEffect(() => {
    updatedPatient && setUpdatedPatient({
      ...updatedPatient,
      whatsappPhoneNum: patientPhoneCountryCode + patientPhoneNumber
    });
  }, [patientPhoneCountryCode, patientPhoneNumber])

  const isSame = (a: PatientDto, b: PatientDto) => {
    return JSON.stringify(a) === JSON.stringify(b);
  };

  const handleCustomFieldChange = (key: string, value: string) => {
    const newMetadata = {
      ...updatedPatient.metadata || {},
      [key]: value
    }

    setUpdatedPatient({
      ...updatedPatient,
      metadata: newMetadata
    })
  }

  const handleSubmit = async () => {
    try {
      const updated = await updatePatient(updatedPatient.id, updatedPatient);
      if (updated) {
        setInitialPatient(updated.data);
        setUpdatedPatient(updated.data);
        toast({
          title: "Updated client",
          description: `${updatedPatient.name} has been updated successfully`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Failed to update client",
          description: "An error occurred",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (err: any) {
      toast({
        title: "Failed to update client",
        description: err?.response?.data?.message || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <SimpleGrid
        minChildWidth={"xs"}
        spacing={8}
        py={2}
      >
        <FormControl isRequired>
          <FormLabel fontFamily="heading" fontSize={{ base: "md", "2xl": "lg" }} color={`${theme}.600`}>Client ID</FormLabel>
          <Input
            value={updatedPatient?.clientId}
            onChange={(e) =>
              updatedPatient &&
              setUpdatedPatient({
                ...updatedPatient,
                clientId: e.target.value,
              })
            }
            fontSize={{ base: "md", "2xl": "lg" }}
            fontWeight="medium"
            color={`${theme}.900`}
            variant={"flushed"}
          />
        </FormControl>
        <FormControl>
          <FormLabel fontFamily="heading" fontSize={{ base: "md", "2xl": "lg" }} color={`${theme}.600`}>Preferred Name</FormLabel>
          <Input
            value={updatedPatient?.preferredName}
            onChange={(e) =>
              updatedPatient &&
              setUpdatedPatient({
                ...updatedPatient,
                preferredName: e.target.value,
              })
            }
            fontSize={{ base: "md", "2xl": "lg" }}
            fontWeight="medium"
            color={`${theme}.900`}
            variant={"flushed"}
          />
        </FormControl>
        <FormControl >
          <FormLabel fontFamily="heading" fontSize={{ base: "md", "2xl": "lg" }} color={`${theme}.600`}>Nationality</FormLabel>
          <Input
            value={updatedPatient.nationality}
            onChange={(e) =>
              updatedPatient &&
              setUpdatedPatient({
                ...updatedPatient,
                nationality: e.target.value,
              })
            }
            fontSize={{ base: "md", "2xl": "lg" }}
            fontWeight="medium"
            color={`${theme}.900`}
            variant={"flushed"}
          />
        </FormControl>
        <FormControl>
          <FormLabel fontFamily="heading" fontSize={{ base: "md", "2xl": "lg" }} color={`${theme}.600`}>National ID</FormLabel>
          <Input
            value={updatedPatient?.nationalId}
            onChange={(e) =>
              updatedPatient &&
              setUpdatedPatient({
                ...updatedPatient,
                nationalId: e.target.value,
              })
            }
            fontSize={{ base: "md", "2xl": "lg" }}
            fontWeight="medium"
            color={`${theme}.900`}
            variant={"flushed"}
          />
        </FormControl>
        <FormControl >
          <FormLabel fontFamily="heading" fontSize={{ base: "md", "2xl": "lg" }} color={`${theme}.600`}>Date of Birth</FormLabel>
          <SingleDatepicker
            triggerVariant="input"
            name="date-input"
            date={updatedPatient.dob || undefined}
            maxDate={new Date()}
            onDateChange={(newDate: Date) => {
              updatedPatient &&
                setUpdatedPatient({
                  ...updatedPatient,
                  dob: new Date(newDate),
                });
            }}
            configs={{
              dateFormat: "d/M/y",
            }}
            propsConfigs={{
              inputProps: {
                fontSize: { base: "md", "2xl": "lg" },
                fontWeight: "medium",
                color: `${theme}.900`,
                width: "full",
                variant: "flushed",
              },
            }}
            usePortal={true}
          />
        </FormControl>
        <FormControl >
          <FormLabel fontFamily="heading" fontSize={{ base: "md", "2xl": "lg" }} color={`${theme}.600`}>Sex</FormLabel>
          <Select
            value={updatedPatient.sex}
            onChange={(e) =>
              updatedPatient &&
              setUpdatedPatient({ ...updatedPatient, sex: e.target.value })
            }
            fontSize={{ base: "md", "2xl": "lg" }}
            fontWeight="medium"
            color={`${theme}.900`}
            variant={"flushed"}
          >
            <option value="">Select one</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel fontFamily="heading" fontSize={{ base: "md", "2xl": "lg" }} color={`${theme}.600`}>Address</FormLabel>
          <Input
            value={updatedPatient.address}
            onChange={(e) =>
              updatedPatient &&
              setUpdatedPatient({ ...updatedPatient, address: e.target.value })
            }
            fontSize={{ base: "md", "2xl": "lg" }}
            fontWeight="medium"
            color={`${theme}.900`}
            variant={"flushed"}
          />
        </FormControl>
        <FormControl>
          <FormLabel fontFamily="heading" fontSize={{ base: "md", "2xl": "lg" }} color={`${theme}.600`}>Email</FormLabel>
          <Input
            value={updatedPatient.email}
            onChange={(e) =>
              updatedPatient &&
              setUpdatedPatient({ ...updatedPatient, email: e.target.value })
            }
            fontSize={{ base: "md", "2xl": "lg" }}
            fontWeight="medium"
            color={`${theme}.900`}
            variant={"flushed"}
          />
        </FormControl>
        <FormControl mb={3} isRequired>
          <FormLabel fontFamily="heading" fontSize={{ base: "md", "2xl": "lg" }} color={`${theme}.600`}>Phone number</FormLabel>
          <HStack>
            <Select
              value={patientPhoneCountryCode}
              placeholder="Code"
              onChange={(e) => setPatientPhoneCountryCode(e.target.value)}
              fontSize={{ base: "md", "2xl": "lg" }}
              fontWeight="medium"
              color={`${theme}.900`}
              variant={"flushed"}
              w="40%"
            >
              {countries.map((country) => (
                <option key={country} value={`+${getCountryCallingCode(country)}`}>
                  {country} (+{getCountryCallingCode(country)})
                </option>
              ))}
            </Select>
            <Input
              value={patientPhoneNumber}
              placeholder="Enter phone number"
              onChange={(e) => setPatientPhoneNumber(e.target.value)}
              fontSize={{ base: "md", "2xl": "lg" }}
              fontWeight="medium"
              color={`${theme}.900`}
              variant={"flushed"}
            />
          </HStack>
        </FormControl>
        <FormControl isRequired>
          <FormLabel fontFamily="heading" fontSize={{ base: "md", "2xl": "lg" }} color={`${theme}.600`}>Preferred Channel</FormLabel>
          <Select
            value={updatedPatient.preferredChannel}
            onChange={(e) =>
              updatedPatient &&
              setUpdatedPatient({
                ...updatedPatient,
                preferredChannel: e.target.value as ProviderType,
              })
            }
            fontSize={{ base: "md", "2xl": "lg" }}
            fontWeight="medium"
            color={`${theme}.900`}
            variant={"flushed"}
          >
            {messagingApps.map((provider) => {
              return (
                <option disabled={!ProviderConfig[provider].enabled} key={provider} value={provider}>
                  {getProviderFriendlyName(provider)}
                </option>
              );
            })}
          </Select>
        </FormControl>
        <FormControl isRequired>
          <FormLabel fontFamily="heading" fontSize={{ base: "md", "2xl": "lg" }} color={`${theme}.600`}>Staff In Charge</FormLabel>
          <StaffSelect
            defaultSelectedId={updatedPatient.staffId}
            onSelectOption={(staffId) => {
              updatedPatient &&
                setUpdatedPatient({
                  ...updatedPatient,
                  staffId
                })
            }}
            inputVariant="flushed"
          />
        </FormControl>
        {
          currentStaff?.organizationClientSchema && currentStaff.organizationClientSchema.map((field, index) => {
            return (
              <CustomClientField
                key={index}
                field={field}
                initialValue={patient.metadata != null ? patient.metadata[field.key] : undefined}
                handleInputChange={handleCustomFieldChange}
              />
            );
          })
        }
      </SimpleGrid >
      <Button
        w={{ base: "xs", "2xl": "xs" }}
        isDisabled={isSame(initialPatient, updatedPatient)}
        mt={4}
        onClick={handleSubmit}
      >
        Save
      </Button>
    </>
  );
};

export default ClientDetail;
