import staffAxios from "./axios/staffAuth";
import { SendMsgResp } from "../types/auth";

const API_URL = process.env.REACT_APP_API_URL || "";

export const resetClientCredentials = async (clientId: string): Promise<SendMsgResp> => {
  try {
    const resp = await staffAxios.post<SendMsgResp>(`${API_URL}/auth/reset_client_credentials`, { clientId });
    return resp.data;
  } catch (error: any) {
    return {
      error: error.response ? error.response.data.message : error.message
    }
  }
} 