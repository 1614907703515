import { FC, useState, useEffect } from "react";
import {
    Box,
    Card,
    Center,
    Flex,
    Heading,
    HStack,
    Spinner,
    Tab,
    TabList,
    Tabs,
    Text,
    VStack,
} from "@chakra-ui/react";
import { theme } from "../../constants";
import { propertyUnits } from "../../constants/properties";
import { Property } from "../../types/protocol";
import { PatientMetricDto } from "../../types/form";
import { toTitleCase } from "../../utils";
import { formatDate } from "../../utils/date";
import { DataPoint, Graph } from "../../components/Graph";
import { getEndOfDayTime, getFirstDate, TimePeriod } from "../../constants/time";
import { useParams } from "react-router-dom";
import { getPatientMetricList } from "../../api/patientMetric";
import {
    groupPatientMetricDtoByTime,
    groupedPatientMetricDtoToDataPoints
} from "../../utils/patientMetric";
import { SingleDatepicker } from "chakra-dayzed-datepicker";

/*
    TODO: improvements we can make to this component (skipped due to lack of time)
    - Currently storing metric values: PatientMetricDto[][] to calculate last recorded time, 
      there should be a better way to do this 
    - We are hardcoding the grouping of blood pressure properties (systolic and dystolic) to display on top, 
      we can consider using metric_detail table to commonly used groups
*/
const HealthDataTracker: FC = () => {
    const timePeriodOptions = {
        [TimePeriod.PAST_DAY]: "Day",
        [TimePeriod.PAST_WEEK]: "Week",
        [TimePeriod.PAST_MONTH]: "Month",
    }

    const { clientId } = useParams();
    const [selectedTimePeriodTab, setSelectedTimePeriodTab] = useState<number>(0);
    const [metricValues, setMetricValues] = useState<PatientMetricDto[][]>([]);
    const [dataPoints, setDataPoints] = useState<DataPoint[][]>([]);
    const [loading, setLoading] = useState(true);
    const [endTime, setEndTime] = useState(new Date().getTime());


    const getPatientMetrics = async (clientId: string) => {
        setLoading(true);
        const metrics = await getPatientMetricList({ patientIds: [clientId] });
        if (!metrics) {
            setLoading(false);
            return
        };
        const metricsGroupedByTime = groupPatientMetricDtoByTime(metrics);
        const dataPoints = groupedPatientMetricDtoToDataPoints(metricsGroupedByTime);
        setLoading(false);
        setMetricValues(metricsGroupedByTime);
        setDataPoints(dataPoints);
    };

    const getSelectedTimePeriod = (): TimePeriod => {
        return Object.keys(timePeriodOptions)[selectedTimePeriodTab] as TimePeriod
    }

    useEffect(() => {
        if (clientId) {
            getPatientMetrics(clientId);
        }
    }, [clientId]);

    const getLatestMetric = (property: string) => {
        // iterate reverse to find the latest metric
        for (let i = metricValues.length - 1; i >= 0; i--) {
            const metric = metricValues[i].find((m) => m.propertyId === property);
            if (metric) return metric;
        }
    };

    const bpSysLatest = getLatestMetric(
        Property.BLOOD_PRESSURE_SYSTOLIC.toString()
    );

    const bpDiaLatest = getLatestMetric(
        Property.BLOOD_PRESSURE_DIASTOLIC.toString()
    );

    if (loading) {
        return (
            <Center>
                <Spinner />
            </Center>
        )
    }

    return (
        <Card p={4} mt={4}>
            <Text mb={2} fontSize={{ base: "lg", "2xl": "xl" }} color={`${theme}.900`} fontWeight={"bold"}>
                Health Data Trackers
            </Text>
            <Tabs
                index={selectedTimePeriodTab}
                onChange={(index) => setSelectedTimePeriodTab(index)}
                colorScheme="blue"
                isFitted
            >
                <TabList>
                    {Object.entries(timePeriodOptions).map(([, value]) => (
                        <Tab>
                            {value}
                        </Tab>
                    ))}
                </TabList>
            </Tabs>
            <HStack justifyContent={"center"} pt={10}>
                <VStack>
                    <Text fontSize={{ base: "sm", "2xl": "md" }} color={`${theme}.900`} fontWeight={"bold"}>
                        Start Date
                    </Text>
                    <SingleDatepicker
                        triggerVariant="input"
                        name="date-input"
                        configs={{
                            dateFormat: "d/M/y",
                        }}
                        onDateChange={() => { }
                        }
                        date={getFirstDate(getSelectedTimePeriod(), endTime)}
                        disabled={true}
                    />
                </VStack>
                <VStack>
                    <Text fontSize={{ base: "sm", "2xl": "md" }} color={`${theme}.900`} fontWeight={"bold"}>
                        End Date
                    </Text>
                    <SingleDatepicker
                        triggerVariant="input"
                        name="date-input"
                        configs={{
                            dateFormat: "d/M/y",
                        }}
                        onDateChange={(date) => { setEndTime(getEndOfDayTime(date)) }}
                        date={new Date(endTime)}
                        maxDate={new Date()}
                    />
                </VStack>
            </HStack>
            {metricValues.length > 0 && (
                <Flex wrap={"wrap"} justifyContent={"center"}>
                    {bpSysLatest && bpDiaLatest && (
                        <Box m={8} w={400}>
                            <Heading color={`${theme}.600`} fontSize={{ base: "sm", "2xl": "md" }}>
                                {toTitleCase("Blood Pressure")}
                            </Heading>
                            <Text fontSize={{ base: "sm", "2xl": "md" }}>
                                {bpSysLatest?.value ?? "-"}/
                                {bpDiaLatest?.value ?? "-"} mmHg
                            </Text>
                            <Text fontSize={{ base: "sm", "2xl": "md" }} mb={4}>
                                Last recorded:{" "}
                                {formatDate(bpSysLatest?.timestamp ?? "")}
                            </Text>
                            <Graph
                                dataPointsGroupedByTime={dataPoints}
                                properties={[
                                    {
                                        id: Property.BLOOD_PRESSURE_SYSTOLIC,
                                        name: toTitleCase(Property.BLOOD_PRESSURE_SYSTOLIC)
                                    },
                                    {
                                        id: Property.BLOOD_PRESSURE_DIASTOLIC,
                                        name: toTitleCase(Property.BLOOD_PRESSURE_DIASTOLIC,)
                                    },
                                ]}
                                selectedTimePeriod={getSelectedTimePeriod()}
                                endTime={endTime}
                            />
                        </Box>
                    )}
                    {Object.keys(Property)
                        .filter(
                            (p) =>
                                !(
                                    p === Property.BLOOD_PRESSURE_DIASTOLIC ||
                                    p === Property.BLOOD_PRESSURE_SYSTOLIC
                                )
                        )
                        .map((key,) => {
                            const metric = getLatestMetric(key);
                            if (!metric) return null;

                            return (
                                <Box m={8} w={400}>
                                    <Heading color={`${theme}.600`} fontSize={{ base: "sm", "2xl": "md" }}>
                                        {toTitleCase(metric?.propertyId)}
                                    </Heading>
                                    <Text fontSize={{ base: "sm", "2xl": "md" }}>
                                        {metric?.value ?? "-"}{" "}
                                        {metric.unit ? metric.unit : ""}
                                        {!metric.unit && metric?.propertyId
                                            ? propertyUnits[ //TODO: client pass UI us using property units from metric detail table and patient info page is using FE hardcoded units. Need to unify these
                                            metric.propertyId as keyof typeof Property
                                            ]
                                            : ""}
                                    </Text>
                                    <Text fontSize={{ base: "sm", "2xl": "md" }} mb={4}>
                                        Last recorded:{" "}
                                        {formatDate(metric?.timestamp ?? "")}
                                    </Text>
                                    <Graph
                                        dataPointsGroupedByTime={dataPoints}
                                        properties={[{ id: key, name: toTitleCase(key) }]}
                                        selectedTimePeriod={getSelectedTimePeriod()}
                                        endTime={endTime}
                                    />
                                </Box>
                            );
                        })}
                </Flex>
            )}
            {metricValues.length === 0 && <Text>No metrics yet!</Text>}
        </Card>
    )
}

export {
    HealthDataTracker
}
